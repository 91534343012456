export const formatPriceCHF = (price: string): string => {
    let priceStr = price.replace('.00', '')

    if (priceStr.includes('.')) {
        return priceStr
    } else {
        return `${priceStr}.-`
    }
}

export const formatPricePerHourWithDiscount = (basePrice: string, discountPercentage: string, hours: number): string => {
    return formatPriceCHF(
        (Number.parseFloat(basePrice) / hours).toString()
    )
}
