import { HomeOutlined } from '@ant-design/icons'
import { BookingVM, getBlockDescription, roomName } from '@kazoo/shared'
import { Button, Popconfirm, Tag } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingTypeIndicator } from '../room-usage-indicator/room-usage-indicator'

interface Props {
    onCancel?: (bookingVM: BookingVM) => void
    booking: BookingVM
}

export const BookingInfo: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const { booking, onCancel } = props

    const start = moment(booking.startDate)
    const end = moment(booking.endDate)
    const duration = moment.duration(end.diff(start))

    return (
        <div key={booking.id} className="py-4 mb-4 border-b border-gray-300 border-solid last:border-b-0 last:mb-0">
            <div className="mb-2">
                <Tag color="geekblue">{start.format('DD-MMM-YYYY')}</Tag>
                <span className="tracking-tighter text-base">{`${start.hour()}:${
                    start.minute() || '00'
                }h - ${end.hour()}:${end.minute() || '00'}h`}</span>
                <span className="ml-2 text-xs">({`${duration.hours()}:${duration.minutes() || '00'}h`})</span>
            </div>
            <div className="flex items-center text-xs">
                <HomeOutlined />
                <span className="ml-1">{roomName[booking.room]}</span>
            </div>
            <div className="flex items-center text-xs">
                <BookingTypeIndicator bookingType={booking.type} />
                <div className="ml-1">{getBlockDescription(booking.type, t, booking.bandName)}</div>
            </div>
            {onCancel && (
                <Popconfirm
                    placement="top"
                    title={t('cancel_booking', { defaultValue: 'Cancel booking?' })}
                    onConfirm={() => onCancel(booking)}
                    okText={t('yes', { defaultValue: 'Yes' })}
                    cancelText={t('no', { defaultValue: 'No' })}
                >
                    <Button className="mt-2" size="small" danger type="ghost">
                        {t('cancel_booking')}
                    </Button>
                </Popconfirm>
            )}
        </div>
    )
}
