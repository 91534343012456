import { LockOutlined, MailOutlined } from '@ant-design/icons'
import useDocumentTitle from '@tanem/use-document-title'
import { Modal, notification, Typography } from 'antd'
import { Field, Formik } from 'formik'
import { Checkbox, Form, Input, SubmitButton } from 'formik-antd'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { appConfig } from '../../app-config'
import { LangSwitcher } from '../../components/lang-switcher/lang-switcher'
import { PhoneInput } from '../../components/phone-input/phone-input'
import { useAuth } from '../../hooks/use-auth'
import KazooLogo from '../../images/logo-white.png'
import { api } from '../../services/api'
import { SignupFormData } from '../../types'
import { phoneValidator } from '../../utils/common-validators'
import { emptyUserData } from '../../utils/default-objects'
import { getCurrentLang } from '../../utils/lang-utils'

export const Register: React.FC = () => {
    useDocumentTitle('Kazoo - Sign up')
    const { t, i18n } = useTranslation()
    const { login } = useAuth()
    const [loading, setLoading] = useState(false)

    const SignupSchema = useMemo(
        () =>
            yup.object().shape({
                firstName: yup
                    .string()
                    .required(t('register_form_name_required', { defaultValue: 'Please enter your first name' })),
                lastName: yup
                    .string()
                    .required(t('register_form_last_name_required', { defaultValue: 'Please enter your last name' })),
                phoneNumber: phoneValidator(t),
                email: yup
                    .string()
                    .required(t('register_form_email_required', { defaultValue: 'Please enter your email' }))
                    .email(t('register_form_invalid_email', { defaultValue: 'Please enter a valid email address' })),
                password: yup
                    .string()
                    .required(t('register_form_password_required', { defaultValue: 'Please enter your password' })),
                acceptTos: yup
                    .boolean()
                    .required('')
                    .oneOf(
                        [true],
                        t('register_form_toc_required', {
                            defaultValue: 'You can only use our services if you agree with our Terms and Conditions'
                        })
                    )
            }),
        [t]
    )

    const onSubmit = async (data: SignupFormData) => {
        setLoading(true)

        try {
            await api.user.create({
                ...data,
                lang: getCurrentLang(i18n)
            })
            await login({ email: data.email, password: data.password })
            setLoading(false)
        } catch (error) {
            void notification.warning({
                message:
                    error?.response?.data?.message ||
                    'An unexpected error occured. The support team has been notified. Please try again.'
            })
            setLoading(false)
        }
    }

    return (
        <>
            <img className="w-32 mx-auto mt-4 lg:mt-8 lg:w-64" src={KazooLogo} alt="kazoo logo" />
            <Modal footer={null} mask={false} centered={true} closable={false} maskClosable={false} visible={true}>
                <p className="text-2xl text-center">{t('Your new Kazoo account')}</p>
                <LangSwitcher />
                <Formik<SignupFormData>
                    initialValues={{ ...emptyUserData, password: '' }}
                    validationSchema={SignupSchema}
                    onSubmit={onSubmit}
                >
                    <Form className="mt-4">
                        <div className="grid grid-cols-2 gap-4">
                            <Form.Item name="firstName" className="mb-4">
                                <Input name="firstName" data-test="firstName" placeholder={t('first_name')} />
                            </Form.Item>
                            <Form.Item name="lastName" className="mb-4">
                                <Input name="lastName" data-test="lastName" placeholder={t('last_name')} />
                            </Form.Item>
                        </div>
                        <Form.Item name="phoneNumber.phoneNumber" className="mb-4">
                            <Field name="phoneNumber" component={PhoneInput} />
                        </Form.Item>
                        <Form.Item name="email" className="mb-4">
                            <Input
                                name="email"
                                data-test="emailInput"
                                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder={t('Email')}
                                type="email"
                            />
                        </Form.Item>
                        <Form.Item name="password" className="mb-4">
                            <Input
                                name="password"
                                data-test="passwordInput"
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder={t('password')}
                                type="password"
                            />
                        </Form.Item>
                        <Form.Item name="acceptTos" className="mb-4">
                            <Checkbox name="acceptTos">
                                {t('i_accept_the')}&nbsp;
                                <Typography.Link
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://kazoo.s3.eu-west-3.amazonaws.com/Kazoo+-+Contrat+d'utilisation+et+conditions+générales.pdf"
                                >
                                    {t('terms_and_conditions')}
                                </Typography.Link>
                            </Checkbox>
                        </Form.Item>
                        <div className="flex flex-col items-center">
                            <SubmitButton loading={loading} data-test="loginSubmit" className="w-4/6 lg:w-2/5">
                                {t('sign_up')}
                            </SubmitButton>
                        </div>
                    </Form>
                </Formik>
                <div className="flex flex-col items-center">
                    <p className="mt-3 text-xs">{t('Already have an account?')}</p>
                    <Link className="text-xs font-bold text-blue-500" to={appConfig.pathnames.login}>
                        {t('sign_in_instead')}
                    </Link>
                </div>
            </Modal>
        </>
    )
}
