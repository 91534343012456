export const appConfig = {
    appVersion: process.env.REACT_APP_VERSION || '',
    apiUrl: process.env.REACT_APP_API_URL || '',
    isProd: process.env.NODE_ENV === 'production',
    locizeApiKeyDevOnly: process.env.REACT_APP_LOCIZE_API_KEY_DEV_ONLY || '',
    locizeProjectId: process.env.REACT_APP_LOCIZE_PROJECT_ID || '',
    websocketUrl: `${process.env.REACT_APP_API_URL}/payment` || '',
    referenceLang: process.env.REACT_APP_REFERENCE_LANG || 'en',
    pathnames: {
        home: '/',
        login: '/login',
        register: '/register',
        account: '/account',
        allBookings: '/bookings',
        book: '/book',
        bands: '/bands',
        bandDetails: '/band-details',
        bookCalendar: '/book/calendar',
        bookSuccess: '/book-success',
        buyCreditHours: '/buy-credit-hours',
        paymentSuccess: '/payment-success',
        paymentFailure: '/payment-failure'
    },
    howItWorksSelectors: {
        body: 'body',
        creditHours: 'credit-hours',
        buyCreditHours: 'buy-credit-hours',
        manageBookings: 'manage-bookings',
        book: 'book'
    }
}
