import { DeleteOutlined, HomeOutlined } from '@ant-design/icons'
import {
    Blocks,
    BlockSelection,
    getBookingDisplayTime,
    getDayBlocks,
    getNumberOfBookings,
    getSortedBlockDays,
    roomName
} from '@kazoo/shared'
import { Button, Tooltip } from 'antd'
import cn from 'classnames'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BookingTypeIndicator } from '../room-usage-indicator/room-usage-indicator'

interface BookingSummaryProps {
    blocks: Blocks
    onBlockDelete?: (blockSelection: BlockSelection) => void
}

export const BookingSummary: React.FC<BookingSummaryProps> = ({ blocks, onBlockDelete }) => {
    const { t } = useTranslation()

    const blockDays = getSortedBlockDays(blocks)
    const numOfBookings = getNumberOfBookings(blocks)

    const handleBlockDelete = useCallback((selection: BlockSelection) => () => onBlockDelete?.(selection), [
        onBlockDelete
    ])

    return (
        <div>
            <div className="mb-2">
                <span>{t('bookings', { defaultValue: 'Bookings' })}</span>
                <span>({numOfBookings})</span>
            </div>
            {blockDays.length > 0 ? (
                blockDays.map((dayObj) => {
                    const dayBlocks = getDayBlocks(blocks, dayObj)

                    return (
                        <div key={dayObj.day()}>
                            <div className="font-semibold">{dayObj.format('LL')}</div>
                            {dayBlocks.map((block, idx) => {
                                const { duration, bookingType, room, id, startBlock } = block
                                const { label, durationInH } = getBookingDisplayTime(startBlock, duration)
                                return (
                                    <div
                                        key={id}
                                        className={cn('flex items-center', {
                                            'mt-2': idx !== 0
                                        })}
                                    >
                                        {onBlockDelete && (
                                            <Tooltip title="Cancel">
                                                <Button
                                                    danger
                                                    type="dashed"
                                                    className="flex items-center justify-center"
                                                    size="small"
                                                    shape="circle"
                                                    icon={<DeleteOutlined />}
                                                    onClick={handleBlockDelete({
                                                        id,
                                                        year: dayObj.year(),
                                                        room,
                                                        dayOfYear: dayObj.dayOfYear(),
                                                        startBlock,
                                                        duration
                                                    })}
                                                />
                                            </Tooltip>
                                        )}
                                        <div className="ml-2">
                                            <div>
                                                <span className="tracking-tighter">{label}</span>
                                                <span className="ml-2 text-xs">({durationInH})</span>
                                            </div>
                                            <div className="flex items-center text-xs">
                                                <HomeOutlined />
                                                <span className="ml-2">{roomName[room]}</span>
                                            </div>
                                            {bookingType && (
                                                <div className="flex items-center text-xs">
                                                    <BookingTypeIndicator
                                                        bookingType={bookingType}
                                                        bandName={block.bandDetails?.name}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })
            ) : (
                <p>{t('no_bookings_selected')}</p>
            )}
        </div>
    )
}
