import Button, { ButtonProps } from 'antd/lib/button'
import cn from 'classnames'
import React, { ReactNode, useState } from 'react'
interface BigassButtonProps extends ButtonProps {
    image?: string
    label?: string
    flipSide?: ReactNode
}

export const BigassButton: React.FC<BigassButtonProps> = ({ children, image, label, flipSide, ...buttonProps }) => {
    const [flipped, setFlipped] = useState(false)

    return (
        <div className="flex flex-col items-center">
            <div className="relative w-full h-full">
                {image && (
                    <img
                        className="absolute border-4 inset-0 object-cover w-full h-full pointer-events-none"
                        alt={children?.toString()}
                        src={image}
                    />
                )}
                <Button
                    onClick={flipSide ? () => setFlipped(true) : undefined}
                    className={cn('w-full h-64 border-4', {
                        'text-white bg-black text-3xl font-bold text-shadow bg-opacity-20 hover:bg-opacity-40 hover:bg-black': image
                    })}
                    {...buttonProps}
                >
                    {label}
                </Button>
                {flipped && <div className="absolute bg-white inset-0 p-4 border-4 rounded">{flipSide}</div>}
            </div>
            <div className="flex items-center">{children}</div>
        </div>
    )
}
