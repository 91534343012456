// singleton to keep the JWT in-memory
class JwtStoreBase {
    private jwt = ''

    async getLocalStorageJwt() {
        try {
            return await localStorage.getItem('jwt')
        } catch (error) {
            console.warn('Error getting localStorage JWT', error)
        }
    }

    async persistJwt(jwt: string) {
        try {
            await localStorage.setItem('jwt', jwt)
            this.setJwt(jwt)
        } catch (error) {
            console.warn('Error persisting JWT', error)
        }
    }

    async clearJwt() {
        try {
            await localStorage.setItem('jwt', '')
            this.setJwt('')
        } catch (error) {
            console.warn('Error clearing JWT', error)
        }
    }

    getJwt(): string {
        return this.jwt
    }

    setJwt(jwt: string) {
        this.jwt = jwt
    }

    getAuthHeaders() {
        return {
            Authorization: `Bearer ${this.getJwt()}`
        }
    }
}

export const JwtStore = new JwtStoreBase()
