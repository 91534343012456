import { UserOutlined } from '@ant-design/icons'
import { BandVM, BookingType } from '@kazoo/shared'
import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { appConfig } from '../../app-config'
import { BigassButton } from '../../components/bigass-button/bigass-button'
import DuoImage from '../../images/duo.jpg'
import LessonImage from '../../images/lesson.jpg'
import BandRehearsalImage from '../../images/rehearsal.jpg'
import SoloImage from '../../images/solo.jpg'
import { goToBands } from '../../utils/routing'

interface Step0BookingTypeProps {
    userBands?: BandVM[]
}

export const Step0BookingType: React.FC<Step0BookingTypeProps> = ({ userBands }) => {
    const { t } = useTranslation()

    return (
        <div className="grid max-w-4xl grid-cols-1 mx-auto gap-x-8 gap-y-8 md:grid-cols-2">
            <Link to={`${appConfig.pathnames.book}/${BookingType.SOLO}`}>
                <BigassButton image={SoloImage} label={t('buy_option_solo', { defaultValue: 'Solo' })}>
                    <UserOutlined />
                    &nbsp;&nbsp;(1 {t('person', { defaultValue: 'person' })})
                </BigassButton>
            </Link>

            <Link to={`${appConfig.pathnames.book}/${BookingType.DUO}`}>
                <BigassButton image={DuoImage} label={t('buy_option_duo', { defaultValue: 'Duo' })}>
                    <UserOutlined /> <UserOutlined />
                    &nbsp;&nbsp;(2 {t('persons', { defaultValue: 'persons' })})
                </BigassButton>
            </Link>

            <BigassButton
                flipSide={
                    <>
                        <p className="text-2xl text-center text-gray-600">
                            {t('choose_the_band', { defaultValue: 'Choose the band' })}
                        </p>
                        {userBands?.length ? (
                            <ul className="mt-4">
                                {userBands.map((band) => (
                                    <Link key={band.id} to={`${appConfig.pathnames.book}/band_${band.id}`}>
                                        <li className="mt-2">{band.name}</li>
                                    </Link>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col justify-center items-center">
                                <p className="mt-4 font-semibold text-red-500">
                                    {t('you_have_no_bands', { defaultValue: 'You have no bands' })}
                                </p>
                                <Link to={goToBands()} className="mt-4">
                                    <Button type="primary">
                                        {t('create_new_band', { defaultValue: 'Create new band' })}
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </>
                }
                image={BandRehearsalImage}
                label={t('buy_option_band', { defaultValue: 'Band' })}
            >
                <UserOutlined /> <UserOutlined /> <UserOutlined />
                &nbsp;&nbsp;(3+ {t('persons')})
            </BigassButton>

            <Link to={`${appConfig.pathnames.book}/${BookingType.LESSON}`}>
                <BigassButton image={LessonImage} label={t('buy_option_lesson', { defaultValue: 'Lesson' })}>
                    <UserOutlined /> <UserOutlined />
                    &nbsp;&nbsp;(2+ {t('persons')})
                </BigassButton>
            </Link>
        </div>
    )
}
