import useDocumentTitle from '@tanem/use-document-title'
import { Button, Card, Spin } from 'antd'
import moment from 'moment'
import React from 'react'
import { useAsync } from 'react-async-hook'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { BookingInfo } from '../../components/booking-info/booking-info'
import { api } from '../../services/api'
import { goToAllBookings, goToBookingWizard } from '../../utils/routing'

export const Home: React.FC<RouteComponentProps> = () => {
    useDocumentTitle('Kazoo - Home')
    const { t } = useTranslation()
    const { result: bookingsResponse, loading } = useAsync(api.bookings.getAllForUser, [])
    const upcomingBookings = bookingsResponse?.data
        ?.filter((booking) => {
            return moment(booking.startDate).isAfter(moment())
        })
        .sort((a, b) => {
            return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        })

    if (loading) {
        return <Spin />
    }

    const upcomingBookingsCard = (
        <Card>
            <div>
                <div className="text-2xl">{t('your_next_bookings', { defaultValue: 'Your next bookings' })}</div>
                <Link className="text-sm text-blue-500" to={goToAllBookings()}>
                    ({t('manage_all_bookings', { defaultValue: 'Manage all bookings' })})
                </Link>
            </div>

            {upcomingBookings?.length === 0 ? (
                <>
                    <div className="mt-4 mb-2">
                        {t('no_upcoming_bookings', { defaultValue: 'No upcoming bookings' })}
                    </div>
                    <Link to={goToBookingWizard()}>
                        <Button type="primary">{t('book_now')}</Button>
                    </Link>
                </>
            ) : (
                upcomingBookings?.map((booking) => <BookingInfo key={booking.id} booking={booking} />)
            )}
        </Card>
    )

    return <div className="grid grid-cols-1 md:grid-cols-2">{upcomingBookingsCard}</div>
}
