import { BookingType, getBlockDescription } from '@kazoo/shared'
import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface RoomUsageIndicatorProps {
    bookingType: BookingType
    bandName?: string
}

export const BookingTypeIndicator: React.FC<RoomUsageIndicatorProps> = ({ bookingType, bandName }) => {
    const { t } = useTranslation()

    return (
        <div className="flex space-x-2 items-center leading-none">
            <div
                className={cn('h-3 w-3 rounded', {
                    'bg-black': bookingType === BookingType.SOLO,
                    'bg-pink-400': bookingType === BookingType.DUO,
                    'bg-yellow-500': bookingType === BookingType.LESSON,
                    'bg-blue-500': bookingType === BookingType.BAND,
                    'bg-red-500': bookingType === BookingType.ADMIN_BLOCK
                })}
            />
            <span>{getBlockDescription(bookingType, t, bandName)}</span>
        </div>
    )
}
