import { CreditType } from '@kazoo/shared'
import { appConfig } from '../app-config'
import { BookWizardParams } from '../pages/book-wizard/book-wizard'

export const goToLogin = (): string => {
    return `${appConfig.pathnames.login}`
}

export const goToHome = (): string => {
    return `/`
}

export const goToAllBookings = (): string => {
    return `${appConfig.pathnames.allBookings}`
}

export const goToBuyCredits = (): string => {
    return `${appConfig.pathnames.buyCreditHours}`
}

export const goToBookingWizard = (): string => {
    return `${appConfig.pathnames.book}`
}

export const goToBookCalendar = (params: BookWizardParams, room: string): string => {
    return `${appConfig.pathnames.book}/${params.bookingType}/${room}/calendar`
}

export const goToBookSuccess = (): string => {
    return `${appConfig.pathnames.bookSuccess}`
}

export const goToBookRoom = (bookingType?: string): string => {
    return `${appConfig.pathnames.book}/${bookingType}`
}

export const goToBookComments = (params: BookWizardParams): string => {
    return `${appConfig.pathnames.book}/${params.bookingType}/${params.room}/comments`
}

export const goToBuyCreditType = (creditType: CreditType): string => {
    return `${appConfig.pathnames.buyCreditHours}/${creditType}`
}

export const goToBands = (): string => {
    return `${appConfig.pathnames.bands}`
}
