import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Room } from '@kazoo/shared'
import { Button, Carousel } from 'antd'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import EightiesImage1 from '../../images/studio-80s-1.jpeg'
import EightiesImage2 from '../../images/studio-80s-2.jpeg'
import EightiesImage3 from '../../images/studio-80s-3.jpeg'
import EightiesImage4 from '../../images/studio-80s-4.jpeg'
import EightiesImage5 from '../../images/studio-80s-5.jpeg'
import EightiesImage6 from '../../images/studio-80s-6.jpeg'
import NyImage1 from '../../images/studio-ny-1.jpeg'
import NyImage2 from '../../images/studio-ny-2.jpeg'
import NyImage3 from '../../images/studio-ny-3.jpeg'
import NyImage4 from '../../images/studio-ny-4.jpeg'
import NyImage5 from '../../images/studio-ny-5.jpeg'
import StageImage1 from '../../images/studio-stage-1.jpeg'
import StageImage2 from '../../images/studio-stage-2.jpeg'
import StageImage3 from '../../images/studio-stage-3.jpeg'
import StageImage4 from '../../images/studio-stage-4.jpeg'
import StageImage5 from '../../images/studio-stage-5.jpeg'
import StageImage6 from '../../images/studio-stage-6.jpeg'
import WorldImage1 from '../../images/studio-world-1.jpeg'
import WorldImage2 from '../../images/studio-world-2.jpeg'
import WorldImage3 from '../../images/studio-world-3.jpeg'
import WorldImage4 from '../../images/studio-world-4.jpeg'
import WorldImage5 from '../../images/studio-world-5.jpeg'
import { goToBookCalendar } from '../../utils/routing'

interface Step1RoomProps {}

interface RoomCardProps {
    name: string
    images: string[]
    to: string
}

const RoomCard: React.FC<RoomCardProps> = ({ children, name, to, images }) => {
    const ref = useRef<any>(null)
    return (
        <Link to={to} className="h-full">
            <div className="border rounded border-gray-400 cursor-pointer hover:border-blue-400 h-full">
                <div className="relative">
                    <Carousel ref={ref} className="h-52 overflow-hidden rounded-tl rounded-tr" adaptiveHeight={false}>
                        {images.map((i, idx) => (
                            <div key={idx}>
                                <img
                                    style={{ objectPosition: 'center' }}
                                    className="object-cover w-full h-64"
                                    alt="room"
                                    src={i}
                                />
                            </div>
                        ))}
                    </Carousel>
                    <div className="absolute bottom-0 left-0">
                        <Button
                            className="flex items-center justify-center border-b-0 border-l-0 rounded-bl-none rounded-br-none"
                            icon={<ArrowLeftOutlined />}
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                ref?.current?.prev()
                            }}
                        />
                    </div>
                    <div className="absolute bottom-0 right-0">
                        <Button
                            className="flex items-center justify-center border-b-0 border-r-0 rounded-bl-none rounded-br-none"
                            icon={<ArrowRightOutlined />}
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                ref?.current?.next()
                            }}
                        />
                    </div>
                </div>
                <div className="p-4">
                    <p className="mb-2 font-semibold">{name}</p>
                    {children}
                </div>
            </div>
        </Link>
    )
}

export const Step1Room: React.FC<Step1RoomProps> = (props) => {
    const params = useParams()
    const { t } = useTranslation()

    return (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:grid-rows-2 md:gap-8">
            <RoomCard
                images={[StageImage1, StageImage2, StageImage3, StageImage4, StageImage5, StageImage6]}
                to={goToBookCalendar(params, Room.ONE.toString())}
                name="Stage Room"
            >
                <p className="text-sm">
                    {t(
                        'The largest room with top comfort. Equipped with a stage, professional lighting, and top of the line bass equipment. Ideal for preparing for a live gig, live streaming and large band rehearsals.'
                    )}
                </p>
            </RoomCard>
            <RoomCard
                images={[WorldImage1, WorldImage2, WorldImage3, WorldImage4, WorldImage5]}
                to={goToBookCalendar(params, Room.TWO.toString())}
                name="World Room"
            >
                <p className="text-sm">
                    {t(
                        'A room with a mix of decor from around the world. The perfect room when trying to come up with new ideas or creating new songs.'
                    )}
                </p>
            </RoomCard>
            <RoomCard
                images={[NyImage1, NyImage2, NyImage3, NyImage4, NyImage5]}
                to={goToBookCalendar(params, Room.THREE.toString())}
                name="New York Room"
            >
                <p className="text-sm">
                    {t(
                        'Summon a certain type of Groove that can only be found in the small bars of The Village down in Manhattan or the funky basements of Brooklyn. Let the funk and jazz rule!'
                    )}
                </p>
            </RoomCard>
            <RoomCard
                images={[
                    EightiesImage1,
                    EightiesImage2,
                    EightiesImage3,
                    EightiesImage4,
                    EightiesImage5,
                    EightiesImage6
                ]}
                to={goToBookCalendar(params, Room.FOUR.toString())}
                name="80s Room"
            >
                <p className="text-sm">
                    {t(
                        'The colors, the strange jeans, and the mix tapes. Go wild in this room, designed to bring out the fun-loving part of everyone!'
                    )}
                </p>
            </RoomCard>
        </div>
    )
}
