import { Lang, UserVM } from '@kazoo/shared'

export const emptyUserData: UserVM = {
    id: '',
    email: '',
    lastName: '',
    firstName: '',
    createdOn: '',
    lastLogin: '',
    lang: Lang.FR,
    bandHours: 0,
    soloHours: 0,
    duoHours: 0,
    lessonHours: 0,
    firstLogin: false,
    phoneNumber: {
        countryPrefix: 41,
        countryCode: 'CH',
        formattedNumber: '',
        phoneNumber: ''
    }
}
