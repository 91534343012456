import useBreakpoint from '@w11r/use-breakpoint'
import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Joyride, { ACTIONS, CallBackProps, STATUS, Step } from 'react-joyride'
import { appConfig } from '../../app-config'

interface HowItWorksProps {
    run: boolean
    stop: () => void
}

export const HowItWorks: React.FunctionComponent<HowItWorksProps> = ({ run, stop }) => {
    const { t } = useTranslation()
    const { isMobile } = useBreakpoint()
    const [currentStep, setCurrentStep] = useState(0)

    const steps: Step[] = [
        {
            title: (
                <Trans className="text-center" i18nKey="how_it_works_welcome_to_the_kazoo_app">
                    <p className="font-semibold">
                        Welcome to the Kazoo application!
                        <br />
                        Here's how it works:
                    </p>
                </Trans>
            ),
            content: (
                <Trans i18nKey="how_it_works_step_1">
                    <p>
                        At Kazoo, you use <strong>credit hours</strong> to book a studio.
                    </p>
                    <p className="mt-2">
                        There are <strong>4</strong> types of credit hours - one per each type of studio usage:
                    </p>
                    <p className="mt-4">
                        <strong>Solo - </strong>
                        You are alone in the room 🥷🥁. 1 person.
                    </p>
                    <p className="mt-2">
                        <strong>Duo - </strong>
                        You and a friend are in the room 👯‍♂️. 2 people.
                    </p>
                    <p className="mt-2">
                        <strong>Band - </strong>
                        For you and your band mates 🎤🎹🥁🎸. 3+ people.
                    </p>
                    <p className="mt-2">
                        <strong>Lesson - </strong>
                        Give a lesson to your student(s) 🎼👨‍🎨. 2+ people.
                    </p>
                </Trans>
            ),
            placement: 'center',
            target: appConfig.howItWorksSelectors.body
        },
        {
            content: (
                <Trans i18nKey="how_it_works_step_2">
                    <p>This is where you can check the hour credits you have.</p>

                    <p className="mt-2">
                        As a new member, we are pleased to offer you <strong>1h</strong> of <strong>solo</strong>{' '}
                        credits, and <strong>1h</strong> of <strong>duo</strong> credits! 😎 Rock ON! 😎
                    </p>
                </Trans>
            ),

            spotlightPadding: 20,
            target: `#${appConfig.howItWorksSelectors.creditHours}`
        },
        {
            content: (
                <Trans i18nKey="how_it_works_step_3">
                    <p>Use your hour credits to book a studio here.</p>
                </Trans>
            ),
            spotlightPadding: 0,
            target: `#${appConfig.howItWorksSelectors.book}`
        },
        {
            content: (
                <Trans i18nKey="how_it_works_step_4">
                    <p>Buy hour credits to add them to your account</p>
                </Trans>
            ),
            spotlightPadding: 0,
            target: `#${appConfig.howItWorksSelectors.buyCreditHours}`
        },
        {
            content: (
                <Trans i18nKey="how_it_works_step_5">
                    <p>
                        See all and cancel your bookings if you need. You can cancel any booking up to 4h before the
                        start. The credits will go back to your account :-).
                    </p>
                </Trans>
            ),
            spotlightPadding: 0,
            target: `#${appConfig.howItWorksSelectors.manageBookings}`
        },
        {
            content: (
                <Trans i18nKey="how_it_works_step_6_final">
                    <p className="text-center">
                        We're here to help. If you have any questions, just drop us an email or call us!
                    </p>
                    <p className="mt-2 font-semibold text-center">
                        <a href="tel:0041767332379">0041 76 733 23 79</a>
                    </p>
                    <p className="mt-2 font-semibold text-center">
                        <a href="mailto: info@kazoomusicstudios.com">info@kazoomusicstudios.com</a>
                    </p>
                    <p className="mt-8 font-semibold text-center">See you soon at the studios!</p>
                </Trans>
            ),
            spotlightPadding: 20,
            target: `body`,
            placement: 'center'
        }
    ]

    const handleJoyrideCallback = useCallback(
        (data: CallBackProps) => {
            const { status, index, action } = data
            const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED, ACTIONS.CLOSE, ACTIONS.STOP]
            setCurrentStep(index)
            if (finishedStatuses.includes(status) || finishedStatuses.includes(action)) {
                stop()
            }
        },
        [stop]
    )

    return (
        <div>
            <Joyride
                callback={handleJoyrideCallback}
                run={run}
                continuous={true}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                steps={steps}
                locale={{
                    back: t('back'),
                    skip: t('skip'),
                    next: t('next'),
                    last: t('last')
                }}
                styles={{
                    options: {
                        width: isMobile ? '90vw' : '585px',
                        zIndex: 1000
                    },
                    tooltipContent: {
                        textAlign: 'left',
                        fontSize: '1rem'
                    },
                    buttonNext: {
                        backgroundColor: '#1890ff'
                    },
                    spotlight: {
                        // so it overlays the sidebar links without issue
                        ...(currentStep === 2 || currentStep === 3 || currentStep === 4
                            ? {
                                  backgroundColor: 'rgba(255, 255, 255, 0.8)'
                              }
                            : {})
                    }
                }}
            />
        </div>
    )
}
