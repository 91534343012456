import cn from 'classnames'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import React from 'react'
import { momentTimezone } from "@kazoo/shared";

const moment = extendMoment(Moment)

interface Props {
    weekRange: moment.Moment[]
}

const today = moment.tz(momentTimezone)

export const CalendarHeader: React.FC<Props> = ({ weekRange }) => {
    return (
        <div className="flex">
            <div className="w-10" />
            {weekRange.map((dayObj) => {
                const isToday = dayObj.dayOfYear() === today.dayOfYear()
                return (
                    <div className={'flex flex-1 flex-col text-center'} key={dayObj.day()}>
                        <div
                            className={cn('text-xs', {
                                'font-bold text-blue-500': isToday
                            })}
                        >
                            {dayObj.format('ddd')}
                        </div>
                        <div>
                            <span
                                className={cn('text-xl py-1 px-3', {
                                    'bg-blue-500 text-white border rounded-full': isToday
                                })}
                            >
                                {dayObj.format('D')}
                            </span>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
