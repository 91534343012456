import { Blocks, BlockSelection, Room } from '@kazoo/shared'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import React from 'react'
import { CalendarCols } from './calendar-cols'
import { CalendarControls } from './calendar-controls'
import { CalendarHeader } from './calendar-header'
import { CalendarHours } from './calendar-hours'
const moment = extendMoment(Moment)

interface Props {
    blocks: Blocks
    room: Room
    weekRange: moment.Moment[]
    hourRange: moment.Moment[]
    onWeekRangeChange: (weekRange: moment.Moment[]) => void
    onBlockSelect: (blockSelection: BlockSelection) => void
    onBlockDelete: (blockSelection: BlockSelection) => void
}

export const Calendar: React.FC<Props> = ({
    room,
    weekRange,
    onBlockSelect,
    onBlockDelete,
    blocks,
    hourRange,
    onWeekRangeChange
}) => {
    return (
        <div className="w-full">
            <div className="w-full mx-auto">
                <CalendarControls weekRange={weekRange} onWeekRangeChange={onWeekRangeChange} />
            </div>
            <div className="mt-2 md:mt-4">
                <CalendarHeader weekRange={weekRange} />
            </div>
            <div className="flex mt-0 md:mt-2">
                <CalendarHours hourRange={hourRange} />
                <CalendarCols
                    room={room}
                    blocks={blocks}
                    hourRange={hourRange}
                    weekRange={weekRange}
                    onBlockSelect={onBlockSelect}
                    onBlockDelete={onBlockDelete}
                />
            </div>
        </div>
    )
}
