import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { BandVM } from '@kazoo/shared'
import { BandMemberDto } from '@kazoo/shared/dist/dto/band-member.dto'
import useDocumentTitle from '@tanem/use-document-title'
import useBreakpoint from '@w11r/use-breakpoint'
import { Button, Card, Modal, notification, Tag } from 'antd'
import { AxiosError } from 'axios'
import cn from 'classnames'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { Form, Input, Select } from 'formik-antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useAsync, useAsyncCallback } from 'react-async-hook'
import { Trans, useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import * as yup from 'yup'
import { useAuth } from '../../hooks/use-auth'
import { api } from '../../services/api'
import { NewBandFormData } from '../../types'
import { BandCard } from './band-card'

const emptyBandMember: BandMemberDto = {
    email: '',
    instruments: []
}

export const Bands: React.FC<RouteComponentProps> = () => {
    useDocumentTitle('Kazoo - Bands')
    const { isMobile } = useBreakpoint()
    const { user, refreshUser } = useAuth()
    const { t, i18n } = useTranslation()
    const [editingBand, setEditingBand] = useState<BandVM | undefined>()
    const [, setCreatingBand] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const { error, result: bandsResponse, execute: loadBands } = useAsync(api.bands.getAll, [])
    const { result: instrumentsResponse, execute: loadInstruments } = useAsyncCallback(api.instruments.getAll)

    const newBandYupSchema = useMemo(
        () =>
            yup.object<NewBandFormData>().shape({
                name: yup
                    .string()
                    .required(t('band_form_name_required', { defaultValue: 'Please enter the band name' })),
                members: yup
                    .array()
                    .of(
                        yup.object<BandMemberDto>().shape({
                            email: yup
                                .string()
                                .required(
                                    t('band_form_email_required', {
                                        defaultValue: 'Please enter the email address of your band member'
                                    })
                                )
                                .email(
                                    t('band_form_invalid_required', {
                                        defaultValue: 'Please enter a valid email address'
                                    })
                                ),
                            instruments: yup
                                .array()
                                .required(
                                    t('band_form_instrument_required', {
                                        defaultValue: 'Please enter the instrument(s) your band member plays'
                                    })
                                )
                                .of(yup.string())
                        })
                    )
                    .required(
                        t('band_form_members_info_required', {
                            defaultValue: 'Please fill out the information about your band members'
                        })
                    )
                    .min(
                        2,
                        t('band_form_email_required', {
                            defaultValue: 'Please add at least 2 band members'
                        })
                    )
            }),
        [t]
    )

    useEffect(() => {
        if (error) {
            notification.error({
                message: `${t('band_page_loading_error', {
                    defaultValue: 'There was an error loading your bands'
                })} - ${error.message}`
            })
        }
    }, [error, t])

    useEffect(() => {
        if (showForm) {
            loadInstruments()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showForm])

    const onBandCreate = (data: NewBandFormData) => {
        if (!editingBand) {
            setCreatingBand(true)

            api.bands
                .create({
                    name: data.name,
                    members: data.members
                })
                .then(() => {
                    setShowForm(false)
                    notification.success({
                        message: t('band_created_success', {
                            defaultValue: 'Your band {{bandName}} was successfully created',
                            bandName: data.name
                        })
                    })
                    refreshUser()
                    loadBands()
                    formik.resetForm()
                })
                .catch((error: AxiosError) => {
                    notification.error({
                        message: `${t('band_create_error', {
                            defaultValue: 'There was an error creating your band'
                        })} - ${error.message ?? error.response?.data?.message}`
                    })
                })
                .finally(() => [setCreatingBand(false)])
        } else {
            api.bands
                .edit({
                    id: editingBand.id,
                    name: data.name,
                    members: data.members
                })
                .then((response) => {
                    if (!response.data.ok) {
                        throw new Error()
                    }

                    void notification.success({ message: t('band_edit_success', { defaultValue: 'Edit successful' }) })
                    setEditingBand(undefined)
                    setShowForm(false)
                    loadBands()
                    formik.resetForm()
                })
                .catch((error: AxiosError) => {
                    notification.error({
                        message: `${t('band_edit_error', {
                            defaultValue: 'There was an error while editing your band'
                        })} - ${error.message ?? error.response?.data?.message}`
                    })
                })
        }
    }

    const onBandEditStart = (bandVM: BandVM) => {
        setEditingBand(bandVM)
        setShowForm(true)
    }

    const onBandDelete = (band: BandVM) => {
        api.bands
            .delete({
                id: band.id
            })
            .then((response) => {
                if (!response.data.ok) {
                    throw new Error()
                }
                void notification.success({
                    message: t('band_deleted_success', {
                        defaultValue: 'The band {{bandName}} was successfully deleted',
                        bandName: band.name
                    })
                })
                loadBands()
            })
            .catch((error: AxiosError) => {
                notification.error({
                    message: `${t('band_edit_error')} - ${error.message ?? error.response?.data?.message}`
                })
            })
    }

    const formik = useFormik<NewBandFormData>({
        onSubmit: onBandCreate,
        enableReinitialize: true,
        initialValues: {
            name: editingBand?.name || '',
            members: editingBand
                ? editingBand.members.map((bandMemberVM) => ({
                      email: bandMemberVM.id,
                      instruments: bandMemberVM.instruments.map((i) => i.name)
                  }))
                : [
                      {
                          email: user.email,
                          instruments: []
                      },
                      {
                          email: '',
                          instruments: []
                      },
                      {
                          email: '',
                          instruments: []
                      }
                  ]
        },
        validationSchema: newBandYupSchema
    })
    const { values, handleSubmit, resetForm, errors, setFieldTouched } = formik
    // https://medium.com/code-divoire/how-to-internationalize-a-yup-validation-schema-in-a-react-formik-and-react-i18next-app-689ff3cd978
    i18n.on('languageChanged', () => {
        Object.keys(errors).forEach((fieldName) => {
            setFieldTouched(fieldName)
        })
    })
    console.log({ values, errors })
    const createButton = (
        <Button
            size={isMobile ? 'small' : 'middle'}
            className={cn({
                'mt-8': !user.newBandPromoUsed
            })}
            onClick={() => setShowForm(true)}
            type="primary"
        >
            {t('create_band_button', { defaultValue: 'Create Band' })}
        </Button>
    )

    const promoBanner = (
        <div
            style={{ maxWidth: 500 }}
            className="flex flex-col items-center max-w-6xl p-10 mx-auto bg-white rounded shadow-xs"
        >
            <p className="text-2xl font-semibold text-blue-400 caps-all-small">{t('Launch Promo')}</p>
            <p className="mt-2">
                <Trans i18nKey="new_band_promo_title">
                    Get an immediate&nbsp;
                    <span className="font-bold">FREE 1h Band Hour Credit!</span>
                </Trans>
            </p>
            <p>
                {t('new_band_promo_subtitle', {
                    defaultValue: 'Create a band now and invite your fellow noise makers'
                })}
                &nbsp;&#128522;
            </p>
            {createButton}
        </div>
    )

    return (
        <div>
            {user.newBandPromoUsed ? createButton : promoBanner}

            <p className="mt-4 text-2xl">
                {t('band_page_your_bands', { defaultValue: 'Your bands' })} ({(bandsResponse?.data || []).length})
            </p>

            {bandsResponse?.data.length === 0 && (
                <p className="mt-2">{t('you_have_no_bands', { defaultValue: 'You have no bands' })}</p>
            )}

            {bandsResponse?.data !== undefined && bandsResponse.data.length > 0 && (
                <Card>
                    {bandsResponse.data.map((b, idx) => (
                        <BandCard
                            key={idx}
                            band={b}
                            onEdit={b.owner === user.id ? onBandEditStart : undefined}
                            onDelete={b.owner === user.id ? onBandDelete : undefined}
                        />
                    ))}
                </Card>
            )}

            <FormikProvider value={formik}>
                <Modal
                    keyboard={false}
                    title={
                        editingBand
                            ? `${t('edit_band', { defaultValue: 'Edit band' })}: ${editingBand.name}`
                            : t('create_new_band', { defaultValue: 'Create Band' })
                    }
                    centered={true}
                    visible={showForm}
                    bodyStyle={{
                        maxHeight: '60vh',
                        overflow: 'auto'
                    }}
                    maskClosable={false}
                    onCancel={() => {
                        resetForm()
                        setShowForm(false)
                        setEditingBand(undefined)
                    }}
                    closable={false}
                    footer={
                        <>
                            <Button
                                onClick={() => {
                                    resetForm()
                                    setShowForm(false)
                                    setEditingBand(undefined)
                                }}
                            >
                                {t('cancel', { defaultValue: 'Cancel' })}
                            </Button>
                            <Button type="primary" onClick={() => handleSubmit()} className="ml-4">
                                {t('save', { defaultValue: 'Save' })}
                            </Button>
                        </>
                    }
                >
                    <Form>
                        <Form.Item name="name">
                            <p>{t('band_name', { defaultValue: 'Band Name' })}</p>
                            <Input name="name" data-test="nameInput" placeholder="Noise'Express" />
                        </Form.Item>
                        <p className="mt-3 mb-2 text-lg">
                            {t('band_member_details', { defaultValue: 'Band Member Details' })}
                        </p>
                        <FieldArray
                            name="members"
                            render={(arrayHelpers) => (
                                <div>
                                    {new Array(values.members.length).fill(0).map((_, idx) => (
                                        <div key={idx}>
                                            <span className="text-xs flex items-center">
                                                {idx !== 0 && (
                                                    <Button
                                                        type="dashed"
                                                        shape="circle"
                                                        size="small"
                                                        onClick={() => arrayHelpers.remove(idx)}
                                                        className="mr-1 flex items-center justify-center"
                                                        icon={<CloseOutlined />}
                                                    />
                                                )}
                                                {idx === 0
                                                    ? t('band_your_details', { defaultValue: 'Your Details' })
                                                    : `${t('band_member', { defaultValue: 'Member' })} ${idx + 1}`}
                                                {idx !== 0 && editingBand?.members[idx]?.name && (
                                                    <span>&nbsp;({editingBand.members[idx].name})</span>
                                                )}
                                            </span>
                                            <Form.Item className="mb-2 mt-1" name={`members.${idx}.email`}>
                                                <Input
                                                    placeholder="Email"
                                                    disabled={idx === 0}
                                                    name={`members.${idx}.email`}
                                                />
                                            </Form.Item>
                                            <Form.Item name={`members.${idx}.instruments`}>
                                                <Select
                                                    allowClear
                                                    name={`members.${idx}.instruments`}
                                                    mode="tags"
                                                    style={{ width: '100%' }}
                                                    placeholder={t('instrument_or_instruments', {
                                                        defaultValue: 'Instrument(s)'
                                                    })}
                                                    tagRender={(props) => (
                                                        <Tag {...props} color="gold">
                                                            {props.label}
                                                        </Tag>
                                                    )}
                                                >
                                                    {instrumentsResponse?.data.map((i) => (
                                                        <Select.Option key={i.name} value={i.name}>
                                                            {i.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    ))}
                                    <Button
                                        block
                                        type="primary"
                                        onClick={() => arrayHelpers.push(emptyBandMember)}
                                        className="mt-2 flex items-center justify-center"
                                        icon={<PlusOutlined />}
                                    >
                                        {t('add_member', { defaultValue: 'Add member' })}
                                    </Button>
                                </div>
                            )}
                        />
                    </Form>
                </Modal>
            </FormikProvider>
        </div>
    )
}
