import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { getWeekRange, momentTimezone } from "@kazoo/shared";
import useBreakpoint from '@w11r/use-breakpoint'
import { Button } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const moment = extendMoment(Moment as any)

interface CalendarControlsProps {
    weekRange: moment.Moment[]
    onWeekRangeChange: (weekRange: moment.Moment[]) => void
}

const today = moment.tz(momentTimezone)

export const CalendarControls: React.FC<CalendarControlsProps> = ({ weekRange, onWeekRangeChange }) => {
    const { t } = useTranslation()
    const { isMobile } = useBreakpoint()

    const firstDay = weekRange[0]
    const lastDay = weekRange[weekRange.length - 1]
    const canGoToPreviousWeek = firstDay.isAfter(today)
    const canGoToNextWeek = lastDay.diff(today, 'months', true) < 3
    const currWeekOffset = firstDay.diff(today.clone().startOf('week'), 'weeks')

    const handleToday = useCallback(() => {
        onWeekRangeChange(getWeekRange())
    }, [onWeekRangeChange])

    const handlePrevWeek = () => {
        onWeekRangeChange(getWeekRange(currWeekOffset - 1))
    }
    const handleNextWeek = () => {
        onWeekRangeChange(getWeekRange(currWeekOffset + 1))
    }

    return (
        <div className="flex items-center">
            <Button size={isMobile ? 'small' : 'middle'} className="text-base" onClick={handleToday}>
                {t('today', { defaultValue: 'Today' })}
            </Button>
            <ButtonGroup className="pl-2">
                <Button
                    size={isMobile ? 'small' : 'middle'}
                    onClick={handlePrevWeek}
                    disabled={!canGoToPreviousWeek}
                    className="flex items-center justify-center"
                    icon={<ArrowLeftOutlined />}
                />
                <Button
                    size={isMobile ? 'small' : 'middle'}
                    onClick={handleNextWeek}
                    disabled={!canGoToNextWeek}
                    className="flex items-center justify-center"
                    icon={<ArrowRightOutlined />}
                />
            </ButtonGroup>

            <div className="ml-2">
                <span>
                    {firstDay.format(isMobile ? 'DD/MM/YY' : 'LL')} - {lastDay.format(isMobile ? 'DD/MM/YY' : 'LL')}
                </span>
            </div>
        </div>
    )
}
