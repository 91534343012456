export interface ICountry {
    name: string
    code: string
    emoji: string
    phoneCode: string
}

export const countries: ICountry[] = [
    {
        name: 'Canada',
        code: 'CA',
        emoji: '🇨🇦',
        phoneCode: '1'
    },
    {
        name: 'United States',
        code: 'US',
        emoji: '🇺🇸',
        phoneCode: '1'
    },
    {
        name: 'Bahamas',
        code: 'BS',
        emoji: '🇧🇸',
        phoneCode: '1242'
    },
    {
        name: 'Barbados',
        code: 'BB',
        emoji: '🇧🇧',
        phoneCode: '1246'
    },
    {
        name: 'Anguilla',
        code: 'AI',
        emoji: '🇦🇮',
        phoneCode: '1264'
    },
    {
        name: 'Antigua and Barbuda',
        code: 'AG',
        emoji: '🇦🇬',
        phoneCode: '1268'
    },
    {
        name: 'Virgin Islands, US',
        code: 'VI',
        phoneCode: '1284',
        emoji: '🇻🇮'
    },
    {
        name: 'Virgin Islands, British',
        code: 'VG',
        emoji: '🇻🇬',
        phoneCode: '1340'
    },
    {
        name: 'Cayman Islands',
        code: 'KY',
        emoji: '🇰🇾',
        phoneCode: '1345'
    },
    {
        name: 'Bermuda',
        code: 'BM',
        emoji: '🇧🇲',
        phoneCode: '1441'
    },
    {
        name: 'Grnameada',
        code: 'GD',
        emoji: '🇬🇩',
        phoneCode: '1473'
    },
    {
        name: 'Turks and Caicos Islands',
        code: 'TC',
        emoji: '🇹🇨',
        phoneCode: '1649'
    },
    {
        name: 'Montserrat',
        code: 'MS',
        emoji: '🇲🇸',
        phoneCode: '1664'
    },
    {
        name: 'Guam',
        code: 'GU',
        emoji: '🇬🇺',
        phoneCode: '1671'
    },
    {
        name: 'American Samoa',
        code: 'AS',
        emoji: '🇦🇸',
        phoneCode: '1684'
    },
    {
        name: 'Saint Maartname (Dutch Part)',
        code: 'SX',
        emoji: '🇳🇱',
        phoneCode: '1721'
    },
    {
        name: 'Saint Lucia',
        code: 'LC',
        emoji: '🇱🇨',
        phoneCode: '1758'
    },
    {
        name: 'Dominica',
        code: 'DM',
        emoji: '🇩🇲',
        phoneCode: '1767'
    },
    {
        name: 'Saint Vincnamet and The Grnameadines',
        code: 'VC',
        emoji: '🇻🇨',
        phoneCode: '1784'
    },
    {
        name: 'Puerto Rico',
        code: 'PR',
        emoji: '🇵🇷',
        phoneCode: '1787'
    },
    {
        name: 'Dominican Republic',
        code: 'DO',
        emoji: '🇩🇴',
        phoneCode: '1809'
    },
    {
        name: 'Trinidad and Tobago',
        code: 'TT',
        emoji: '🇹🇹',
        phoneCode: '1868'
    },
    {
        name: 'Saint Kitts and Nevis',
        code: 'KN',
        emoji: '🇰🇳',
        phoneCode: '1869'
    },
    {
        name: 'Jamaica',
        code: 'JM',
        emoji: '🇯🇲',
        phoneCode: '1876'
    },
    {
        name: 'Egypt',
        code: 'EG',
        emoji: '🇪🇬',
        phoneCode: '20'
    },
    {
        name: 'Morocco',
        code: 'MA',
        emoji: '🇲🇦',
        phoneCode: '212'
    },
    {
        name: 'Algeria',
        code: 'DZ',
        emoji: '🇩🇿',
        phoneCode: '213'
    },
    {
        name: 'Tunisia',
        code: 'TN',
        emoji: '🇹🇳',
        phoneCode: '216'
    },
    {
        name: 'Libya',
        code: 'LY',
        emoji: '🇱🇾',
        phoneCode: '218'
    },
    {
        name: 'Gambia',
        code: 'GM',
        emoji: '🇬🇲',
        phoneCode: '220'
    },
    {
        name: 'Snameegal',
        code: 'SN',
        emoji: '🇸🇳',
        phoneCode: '221'
    },
    {
        name: 'Mauritania',
        code: 'MR',
        emoji: '🇲🇷',
        phoneCode: '222'
    },
    {
        name: 'Mali',
        code: 'ML',
        emoji: '🇲🇱',
        phoneCode: '223'
    },
    {
        name: 'Guinea',
        code: 'GN',
        emoji: '🇬🇳',
        phoneCode: '224'
    },
    {
        name: 'Ivory Coast',
        code: 'CI',
        phoneCode: '225',
        emoji: '🇨🇮'
    },
    {
        name: 'Burkina Faso',
        code: 'BF',
        emoji: '🇧🇫',
        phoneCode: '226'
    },
    {
        name: 'Niger',
        code: 'NE',
        emoji: '🇳🇪',
        phoneCode: '227'
    },
    {
        name: 'Togo',
        code: 'TG',
        emoji: '🇹🇬',
        phoneCode: '228'
    },
    {
        name: 'Bnamein',
        code: 'BJ',
        emoji: '🇧🇯',
        phoneCode: '229'
    },
    {
        name: 'Mauritius',
        code: 'MU',
        emoji: '🇲🇺',
        phoneCode: '230'
    },
    {
        name: 'Liberia',
        code: 'LR',
        emoji: '🇱🇷',
        phoneCode: '231'
    },
    {
        name: 'Sierra Leone',
        code: 'SL',
        emoji: '🇸🇱',
        phoneCode: '232'
    },
    {
        name: 'Ghana',
        code: 'GH',
        emoji: '🇬🇭',
        phoneCode: '233'
    },
    {
        name: 'Nigeria',
        code: 'NG',
        emoji: '🇳🇬',
        phoneCode: '234'
    },
    {
        name: 'Chad',
        code: 'TD',
        emoji: '🇹🇩',
        phoneCode: '235'
    },
    {
        name: 'Cnametral African Republic',
        code: 'CF',
        emoji: '🇨🇫',
        phoneCode: '236'
    },
    {
        name: 'Cameroon',
        code: 'CM',
        emoji: '🇨🇲',
        phoneCode: '237'
    },
    {
        name: 'Cape Verde',
        code: 'CV',
        emoji: '🇨🇻',
        phoneCode: '238'
    },
    {
        name: 'Sao Tome and Principe',
        code: 'ST',
        emoji: '🇸🇹',
        phoneCode: '239'
    },
    {
        name: 'Equatorial Guinea',
        code: 'GQ',
        emoji: '🇬🇶',
        phoneCode: '240'
    },
    {
        name: 'Gabon',
        code: 'GA',
        emoji: '🇬🇦',
        phoneCode: '241'
    },
    {
        name: 'Republic Of The Congo',
        code: 'CG',
        phoneCode: '242',
        emoji: '🇨🇬'
    },
    {
        name: 'Democratic Republic of the Congo',
        code: 'CD',
        phoneCode: '243',
        emoji: '🇨🇩'
    },
    {
        name: 'Angola',
        code: 'AO',
        emoji: '🇦🇴',
        phoneCode: '244'
    },
    {
        name: 'Guinea-Bissau',
        code: 'GW',
        emoji: '🇬🇼',
        phoneCode: '245'
    },
    {
        name: 'Seychelles',
        code: 'SC',
        emoji: '🇸🇨',
        phoneCode: '248'
    },
    {
        name: 'Sudan',
        code: 'SD',
        emoji: '🇸🇩',
        phoneCode: '249'
    },
    {
        name: 'Rwanda',
        code: 'RW',
        emoji: '🇷🇼',
        phoneCode: '250'
    },
    {
        name: 'Ethiopia',
        code: 'ET',
        emoji: '🇪🇹',
        phoneCode: '251'
    },
    {
        name: 'Somalia',
        code: 'SO',
        emoji: '🇸🇴',
        phoneCode: '252'
    },
    {
        name: 'Djibouti',
        code: 'DJ',
        emoji: '🇩🇯',
        phoneCode: '253'
    },
    {
        name: 'Knameya',
        code: 'KE',
        emoji: '🇰🇪',
        phoneCode: '254'
    },
    {
        name: 'Tanzania',
        code: 'TZ',
        emoji: '🇹🇿',
        phoneCode: '255'
    },
    {
        name: 'Uganda',
        code: 'UG',
        emoji: '🇺🇬',
        phoneCode: '256'
    },
    {
        name: 'Burundi',
        code: 'BI',
        emoji: '🇧🇮',
        phoneCode: '257'
    },
    {
        name: 'Mozambique',
        code: 'MZ',
        emoji: '🇲🇿',
        phoneCode: '258'
    },
    {
        name: 'Zambia',
        code: 'ZM',
        emoji: '🇿🇲',
        phoneCode: '260'
    },
    {
        name: 'Madagascar',
        code: 'MG',
        emoji: '🇲🇬',
        phoneCode: '261'
    },
    {
        name: 'Réunion Island',
        code: 'RE',
        phoneCode: '262',
        emoji: '🇷🇪'
    },
    {
        name: 'Zimbabwe',
        code: 'ZW',
        emoji: '🇿🇼',
        phoneCode: '263'
    },
    {
        name: 'Namibia',
        code: 'NA',
        emoji: '🇳🇦',
        phoneCode: '264'
    },
    {
        name: 'Malawi',
        code: 'MW',
        emoji: '🇲🇼',
        phoneCode: '265'
    },
    {
        name: 'Lesotho',
        code: 'LS',
        emoji: '🇱🇸',
        phoneCode: '266'
    },
    {
        name: 'Botswana',
        code: 'BW',
        emoji: '🇧🇼',
        phoneCode: '267'
    },
    {
        name: 'Swaziland',
        code: 'SZ',
        emoji: '🇸🇿',
        phoneCode: '268'
    },
    {
        name: 'Comoros',
        code: 'KM',
        emoji: '🇰🇲',
        phoneCode: '269'
    },
    {
        name: 'Mayotte',
        code: 'YT',
        emoji: '🇾🇹',
        phoneCode: '269'
    },
    {
        name: 'South Africa',
        code: 'ZA',
        emoji: '🇿🇦',
        phoneCode: '27'
    },
    {
        name: 'Eritrea',
        code: 'ER',
        emoji: '🇪🇷',
        phoneCode: '291'
    },
    {
        name: 'Aruba',
        code: 'AW',
        emoji: '🇦🇼',
        phoneCode: '297'
    },
    {
        name: 'Faroe Islands',
        code: 'FO',
        emoji: '🇫🇴',
        phoneCode: '298'
    },
    {
        name: 'Grenameland',
        code: 'GL',
        emoji: '🇬🇱',
        phoneCode: '299'
    },
    {
        name: 'Greece',
        code: 'GR',
        emoji: '🇬🇷',
        phoneCode: '30'
    },
    {
        name: 'Netherlands',
        code: 'NL',
        emoji: '🇳🇱',
        phoneCode: '31'
    },
    {
        name: 'Belgium',
        code: 'BE',
        emoji: '🇧🇪',
        phoneCode: '32'
    },
    {
        name: 'France',
        code: 'FR',
        emoji: '🇫🇷',
        phoneCode: '33'
    },
    {
        name: 'Spain',
        code: 'ES',
        emoji: '🇪🇸',
        phoneCode: '34'
    },
    {
        name: 'Gibraltar',
        code: 'GI',
        emoji: '🇬🇮',
        phoneCode: '350'
    },
    {
        name: 'Portugal',
        code: 'PT',
        emoji: '🇵🇹',
        phoneCode: '351'
    },
    {
        name: 'Luxembourg',
        code: 'LU',
        emoji: '🇱🇺',
        phoneCode: '352'
    },
    {
        name: 'Ireland',
        code: 'IE',
        emoji: '🇮🇪',
        phoneCode: '353'
    },
    {
        name: 'Iceland',
        code: 'IS',
        emoji: '🇮🇸',
        phoneCode: '354'
    },
    {
        name: 'Albania',
        code: 'AL',
        emoji: '🇦🇱',
        phoneCode: '355'
    },
    {
        name: 'Malta',
        code: 'MT',
        emoji: '🇲🇹',
        phoneCode: '356'
    },
    {
        name: 'Cyprus',
        code: 'CY',
        emoji: '🇨🇾',
        phoneCode: '357'
    },
    {
        name: 'Finland',
        code: 'FI',
        emoji: '🇫🇮',
        phoneCode: '358'
    },
    {
        name: 'Bulgaria',
        code: 'BG',
        emoji: '🇧🇬',
        phoneCode: '359'
    },
    {
        name: 'Hungary',
        code: 'HU',
        emoji: '🇭🇺',
        phoneCode: '36'
    },
    {
        name: 'Lithuania',
        code: 'LT',
        emoji: '🇱🇹',
        phoneCode: '370'
    },
    {
        name: 'Latvia',
        code: 'LV',
        emoji: '🇱🇻',
        phoneCode: '371'
    },
    {
        name: 'Estonia',
        code: 'EE',
        emoji: '🇪🇪',
        phoneCode: '372'
    },
    {
        name: 'Moldova',
        code: 'MD',
        emoji: '🇲🇩',
        phoneCode: '373'
    },
    {
        name: 'Armnameia',
        code: 'AM',
        emoji: '🇦🇲',
        phoneCode: '374'
    },
    {
        name: 'Belarus',
        code: 'BY',
        emoji: '🇧🇾',
        phoneCode: '375'
    },
    {
        name: 'Andorra',
        code: 'AD',
        emoji: '🇦🇩',
        phoneCode: '376'
    },
    {
        name: 'Monaco',
        code: 'MC',
        emoji: '🇲🇨',
        phoneCode: '377'
    },
    {
        name: 'San Marino',
        code: 'SM',
        emoji: '🇸🇲',
        phoneCode: '378'
    },
    {
        name: 'Ukraine',
        code: 'UA',
        emoji: '🇺🇦',
        phoneCode: '380'
    },
    {
        name: 'Serbia',
        code: 'RS',
        emoji: '🇷🇸',
        phoneCode: '381'
    },
    {
        name: 'Montnameegro',
        code: 'ME',
        emoji: '🇲🇪',
        phoneCode: '382'
    },
    {
        name: 'Croatia',
        code: 'HR',
        emoji: '🇭🇷',
        phoneCode: '385'
    },
    {
        name: 'Slovnameia',
        code: 'SI',
        emoji: '🇸🇮',
        phoneCode: '386'
    },
    {
        name: 'Bosnia and Herzegovina',
        code: 'BA',
        emoji: '🇧🇦',
        phoneCode: '387'
    },
    {
        name: 'Macedonia',
        code: 'MK',
        emoji: '🇲🇰',
        phoneCode: '389'
    },
    {
        name: 'Italy',
        code: 'IT',
        emoji: '🇮🇹',
        phoneCode: '39'
    },
    {
        name: 'Romania',
        code: 'RO',
        emoji: '🇷🇴',
        phoneCode: '40'
    },
    {
        name: 'Switzerland',
        code: 'CH',
        emoji: '🇨🇭',
        phoneCode: '41'
    },
    {
        name: 'Czech',
        code: 'CZ',
        emoji: '🇨🇿',
        phoneCode: '420'
    },
    {
        name: 'Slovakia',
        code: 'SK',
        emoji: '🇸🇰',
        phoneCode: '421'
    },
    {
        name: 'Liechtnamestein',
        code: 'LI',
        emoji: '🇱🇮',
        phoneCode: '423'
    },
    {
        name: 'Austria',
        code: 'AT',
        emoji: '🇦🇹',
        phoneCode: '43'
    },
    {
        name: 'United Kingdom',
        code: 'GB',
        emoji: '🇬🇧',
        phoneCode: '44'
    },
    {
        name: 'Dnamemark',
        code: 'DK',
        emoji: '🇩🇰',
        phoneCode: '45'
    },
    {
        name: 'Swedname',
        code: 'SE',
        emoji: '🇸🇪',
        phoneCode: '46'
    },
    {
        name: 'Norway',
        code: 'NO',
        emoji: '🇳🇴',
        phoneCode: '47'
    },
    {
        name: 'Poland',
        code: 'PL',
        emoji: '🇵🇱',
        phoneCode: '48'
    },
    {
        name: 'Germany',
        code: 'DE',
        emoji: '🇩🇪',
        phoneCode: '49'
    },
    {
        name: 'Belize',
        code: 'BZ',
        emoji: '🇧🇿',
        phoneCode: '501'
    },
    {
        name: 'Guatemala',
        code: 'GT',
        emoji: '🇬🇹',
        phoneCode: '502'
    },
    {
        name: 'El Salvador',
        code: 'SV',
        emoji: '🇸🇻',
        phoneCode: '503'
    },
    {
        name: 'Honduras',
        code: 'HN',
        emoji: '🇭🇳',
        phoneCode: '504'
    },
    {
        name: 'Nicaragua',
        code: 'NI',
        emoji: '🇳🇮',
        phoneCode: '505'
    },
    {
        name: 'Costa Rica',
        code: 'CR',
        emoji: '🇨🇷',
        phoneCode: '506'
    },
    {
        name: 'Panama',
        code: 'PA',
        emoji: '🇵🇦',
        phoneCode: '507'
    },
    {
        name: 'Saint Pierre and Miquelon',
        code: 'PM',
        emoji: '🇵🇲',
        phoneCode: '508'
    },
    {
        name: 'Haiti',
        code: 'HT',
        emoji: '🇭🇹',
        phoneCode: '509'
    },
    {
        name: 'Peru',
        code: 'PE',
        emoji: '🇵🇪',
        phoneCode: '51'
    },
    {
        name: 'Mexico',
        code: 'MX',
        emoji: '🇲🇽',
        phoneCode: '52'
    },
    {
        name: 'Cuba',
        code: 'CU',
        emoji: '🇨🇺',
        phoneCode: '53'
    },
    {
        name: 'Argnametina',
        code: 'AR',
        emoji: '🇦🇷',
        phoneCode: '54'
    },
    {
        name: 'Brazil',
        code: 'BR',
        emoji: '🇧🇷',
        phoneCode: '55'
    },
    {
        name: 'Chile',
        code: 'CL',
        emoji: '🇨🇱',
        phoneCode: '56'
    },
    {
        name: 'Colombia',
        code: 'CO',
        emoji: '🇨🇴',
        phoneCode: '57'
    },
    {
        name: 'Vnameezuela',
        code: 'VE',
        emoji: '🇻🇪',
        phoneCode: '58'
    },
    {
        name: 'Guadeloupe',
        code: 'GP',
        emoji: '🇬🇵',
        phoneCode: '590'
    },
    {
        name: 'Bolivia',
        code: 'BO',
        emoji: '🇧🇴',
        phoneCode: '591'
    },
    {
        name: 'Guyana',
        code: 'GY',
        emoji: '🇬🇾',
        phoneCode: '592'
    },
    {
        name: 'Ecuador',
        code: 'EC',
        emoji: '🇪🇨',
        phoneCode: '593'
    },
    {
        name: 'Frnamech Guiana',
        code: 'GF',
        emoji: '🇬🇫',
        phoneCode: '594'
    },
    {
        name: 'Paraguay',
        code: 'PY',
        emoji: '🇵🇾',
        phoneCode: '595'
    },
    {
        name: 'Martinique',
        code: 'MQ',
        emoji: '🇲🇶',
        phoneCode: '596'
    },
    {
        name: 'Suriname',
        code: 'SR',
        emoji: '🇸🇷',
        phoneCode: '597'
    },
    {
        name: 'Uruguay',
        code: 'UY',
        emoji: '🇺🇾',
        phoneCode: '598'
    },
    {
        name: 'Curacao',
        code: 'CW',
        phoneCode: '599',
        emoji: '🇨🇼'
    },
    {
        name: 'Malaysia',
        code: 'MY',
        emoji: '🇲🇾',
        phoneCode: '60'
    },
    {
        name: 'Australia',
        code: 'AU',
        emoji: '🇦🇺',
        phoneCode: '61'
    },
    {
        name: 'Indonesia',
        code: 'ID',
        emoji: '🇮🇩',
        phoneCode: '62'
    },
    {
        name: 'Philippines',
        code: 'PH',
        emoji: '🇵🇭',
        phoneCode: '63'
    },
    {
        name: 'New Zealand',
        code: 'NZ',
        emoji: '🇳🇿',
        phoneCode: '64'
    },
    {
        name: 'Singapore',
        code: 'SG',
        emoji: '🇸🇬',
        phoneCode: '65'
    },
    {
        name: 'Thailand',
        code: 'TH',
        emoji: '🇹🇭',
        phoneCode: '66'
    },
    {
        name: 'Timor-Leste',
        code: 'TL',
        emoji: '🇹🇱',
        phoneCode: '670'
    },
    {
        name: 'Brunei',
        code: 'BN',
        emoji: '🇧🇳',
        phoneCode: '673'
    },
    {
        name: 'Papua New Guinea',
        code: 'PG',
        emoji: '🇵🇬',
        phoneCode: '675'
    },
    {
        name: 'Tonga',
        code: 'TO',
        emoji: '🇹🇴',
        phoneCode: '676'
    },
    {
        name: 'Solomon Islands',
        code: 'SB',
        emoji: '🇸🇧',
        phoneCode: '677'
    },
    {
        name: 'Vanuatu',
        code: 'VU',
        emoji: '🇻🇺',
        phoneCode: '678'
    },
    {
        name: 'Fiji',
        code: 'FJ',
        emoji: '🇫🇯',
        phoneCode: '679'
    },
    {
        name: 'Palau',
        code: 'PW',
        emoji: '🇵🇼',
        phoneCode: '680'
    },
    {
        name: 'Cook Islands',
        code: 'CK',
        emoji: '🇨🇰',
        phoneCode: '682'
    },
    {
        name: 'Samoa',
        code: 'WS',
        emoji: '🇼🇸',
        phoneCode: '685'
    },
    {
        name: 'Kiribati',
        code: 'KI',
        emoji: '🇰🇮',
        phoneCode: '686'
    },
    {
        name: 'New Caledonia',
        code: 'NC',
        emoji: '🇳🇨',
        phoneCode: '687'
    },
    {
        name: 'Frnamech Polynesia',
        code: 'PF',
        emoji: '🇵🇫',
        phoneCode: '689'
    },
    {
        name: 'Kazakhstan',
        code: 'KZ',
        emoji: '🇰🇿',
        phoneCode: '7'
    },
    {
        name: 'Russia',
        code: 'RU',
        emoji: '🇷🇺',
        phoneCode: '7'
    },
    {
        name: 'Japan',
        code: 'JP',
        emoji: '🇯🇵',
        phoneCode: '81'
    },
    {
        name: 'South Korea',
        code: 'KR',
        emoji: '🇰🇷',
        phoneCode: '82'
    },
    {
        name: 'Vietnam',
        code: 'VN',
        emoji: '🇻🇳',
        phoneCode: '84'
    },
    {
        name: 'Hong Kong',
        code: 'HK',
        emoji: '🇭🇰',
        phoneCode: '852'
    },
    {
        name: 'Macau',
        code: 'MO',
        phoneCode: '853',
        emoji: '🇲🇴'
    },
    {
        name: 'Cambodia',
        code: 'KH',
        emoji: '🇰🇭',
        phoneCode: '855'
    },
    {
        name: 'Laos',
        code: 'LA',
        phoneCode: '856',
        emoji: '🇱🇦'
    },
    {
        name: 'China',
        code: 'CN',
        emoji: '🇨🇳',
        phoneCode: '86'
    },
    {
        name: 'Bangladesh',
        code: 'BD',
        emoji: '🇧🇩',
        phoneCode: '880'
    },
    {
        name: 'Taiwan',
        code: 'TW',
        emoji: '🇨🇳',
        phoneCode: '886'
    },
    {
        name: 'Turkey',
        code: 'TR',
        emoji: '🇹🇷',
        phoneCode: '90'
    },
    {
        name: 'India',
        code: 'IN',
        emoji: '🇮🇳',
        phoneCode: '91'
    },
    {
        name: 'Pakistan',
        code: 'PK',
        emoji: '🇵🇰',
        phoneCode: '92'
    },
    {
        name: 'Afghanistan',
        code: 'AF',
        emoji: '🇦🇫',
        phoneCode: '93'
    },
    {
        name: 'Sri Lanka',
        code: 'LK',
        emoji: '🇱🇰',
        phoneCode: '94'
    },
    {
        name: 'Myanmar',
        code: 'MM',
        emoji: '🇲🇲',
        phoneCode: '95'
    },
    {
        name: 'Maldives',
        code: 'MV',
        emoji: '🇲🇻',
        phoneCode: '960'
    },
    {
        name: 'Lebanon',
        code: 'LB',
        emoji: '🇱🇧',
        phoneCode: '961'
    },
    {
        name: 'Jordan',
        code: 'JO',
        emoji: '🇯🇴',
        phoneCode: '962'
    },
    {
        name: 'Syria',
        code: 'SY',
        emoji: '🇸🇾',
        phoneCode: '963'
    },
    {
        name: 'Iraq',
        code: 'IQ',
        emoji: '🇮🇶',
        phoneCode: '964'
    },
    {
        name: 'Kuwait',
        code: 'KW',
        emoji: '🇰🇼',
        phoneCode: '965'
    },
    {
        name: 'Saudi Arabia',
        code: 'SA',
        emoji: '🇸🇦',
        phoneCode: '966'
    },
    {
        name: 'Yemname',
        code: 'YE',
        emoji: '🇾🇪',
        phoneCode: '967'
    },
    {
        name: 'Oman',
        code: 'OM',
        emoji: '🇴🇲',
        phoneCode: '968'
    },
    {
        name: 'Palestine',
        code: 'BL',
        emoji: '🇵🇸',
        phoneCode: '970'
    },
    {
        name: 'United Arab Emirates',
        code: 'AE',
        emoji: '🇦🇪',
        phoneCode: '971'
    },
    {
        name: 'Israel',
        code: 'IL',
        emoji: '🇮🇱',
        phoneCode: '972'
    },
    {
        name: 'Bahrain',
        code: 'BH',
        emoji: '🇧🇭',
        phoneCode: '973'
    },
    {
        name: 'Qatar',
        code: 'QA',
        emoji: '🇶🇦',
        phoneCode: '974'
    },
    {
        name: 'Bhutan',
        code: 'BT',
        emoji: '🇧🇹',
        phoneCode: '975'
    },
    {
        name: 'Mongolia',
        code: 'MN',
        emoji: '🇲🇳',
        phoneCode: '976'
    },
    {
        name: 'Nepal',
        code: 'NP',
        emoji: '🇳🇵',
        phoneCode: '977'
    },
    {
        name: 'Iran',
        code: 'IR',
        emoji: '🇮🇷',
        phoneCode: '98'
    },
    {
        name: 'Tajikistan',
        code: 'TJ',
        emoji: '🇹🇯',
        phoneCode: '992'
    },
    {
        name: 'Turkmnameistan',
        code: 'TM',
        emoji: '🇹🇲',
        phoneCode: '993'
    },
    {
        name: 'Azerbaijan',
        code: 'AZ',
        emoji: '🇦🇿',
        phoneCode: '994'
    },
    {
        name: 'Georgia',
        code: 'GE',
        emoji: '🇬🇪',
        phoneCode: '995'
    },
    {
        name: 'Kyrgyzstan',
        code: 'KG',
        emoji: '🇰🇬',
        phoneCode: '996'
    },
    {
        name: 'Uzbekistan',
        code: 'UZ',
        emoji: '🇺🇿',
        phoneCode: '998'
    }
]
