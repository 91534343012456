import {
    CalendarOutlined,
    CreditCardOutlined,
    HistoryOutlined,
    HomeOutlined,
    QuestionOutlined,
    TeamOutlined
} from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import cn from 'classnames'
import React, { ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { appConfig } from '../../app-config'
import { HowItWorks } from '../../pages/how-it-works/how-it-works'
import { goToAllBookings, goToBands, goToBookingWizard, goToBuyCredits, goToHome } from '../../utils/routing'

export const MobileMenu = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { pathname } = useLocation()

    const [showHowItWorks, setShowHowItWorks] = useState(false)

    const startHowItWorks = useCallback(() => setShowHowItWorks(true), [])
    const stopHowItWorks = useCallback(() => setShowHowItWorks(false), [])

    // TODO deep pathnames need to be active, like book/solo
    console.log({ pathname })

    return (
        <div className="grid grid-cols-6 bg-white w-full fixed bottom-0 right-0 h-16">
            <MenuButton
                active={pathname === appConfig.pathnames.home}
                onClick={() => history.push(goToHome())}
                label={t('menu_home')}
                icon={<HomeOutlined />}
            />
            <MenuButton
                smallText
                active={showHowItWorks}
                onClick={startHowItWorks}
                label={t('menu_how_it_works')}
                icon={<QuestionOutlined />}
            />
            <MenuButton
                active={pathname === appConfig.pathnames.buyCreditHours}
                onClick={() => history.push(goToBuyCredits())}
                label={t('menu_buy')}
                id={appConfig.howItWorksSelectors.buyCreditHours}
                icon={<CreditCardOutlined />}
            />
            <MenuButton
                active={pathname === appConfig.pathnames.bookCalendar}
                onClick={() => history.push(goToBookingWizard())}
                label={t('menu_book')}
                id={appConfig.howItWorksSelectors.book}
                icon={<CalendarOutlined />}
            />
            <MenuButton
                active={pathname === appConfig.pathnames.bands}
                onClick={() => history.push(goToBands())}
                label={t('menu_bands')}
                icon={<TeamOutlined />}
            />
            <MenuButton
                active={pathname === appConfig.pathnames.allBookings}
                onClick={() => history.push(goToAllBookings())}
                label={t('menu_bookings')}
                id={appConfig.howItWorksSelectors.manageBookings}
                icon={<HistoryOutlined />}
            />
            <HowItWorks run={showHowItWorks} stop={stopHowItWorks} />
        </div>
    )
}

interface MenuButtonProps extends ButtonProps {
    active?: boolean
    smallText?: boolean
    label: string
    icon: ReactNode
}

export const MenuButton = (props: MenuButtonProps) => {
    const { icon, label, active, smallText, ...buttonProps } = props

    return (
        <Button
            type={active ? 'primary' : 'default'}
            className="flex flex-col items-center justify-center text-center h-16 border-none rounded-none px-1"
            {...buttonProps}
        >
            <div>{icon}</div>
            <div
                className={cn('text-xs w-full whitespace-normal', {
                    'text-xxs': smallText
                })}
            >
                {label}
            </div>
        </Button>
    )
}
