import { Button, Result } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { appConfig } from '../../app-config'

export const Step4Success: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Result
            status="success"
            title={t('booking_successful', { defaultValue: 'Your booking was successful!' })}
            extra={[
                <Link to={appConfig.pathnames.book} key="1">
                    <Button type="primary">{t('book_again', { defaultValue: 'Book again' })}</Button>
                </Link>,
                <Link to={appConfig.pathnames.buyCreditHours} key="2">
                    <Button>{t('buy_credits', { defaultValue: 'Buy credits' })}</Button>
                </Link>
            ]}
        />
    )
}
