import { Lang } from '@kazoo/shared'
import { i18n } from 'i18next'
import { emptyUserData } from './default-objects'


export const getCurrentLang = (i18n: i18n): Lang => {
    const i18nLang = i18n.language.slice(0, 2).toLowerCase() as Lang

    if ([Lang.EN, Lang.FR].includes(i18nLang)) {
        return i18nLang
    }



    return emptyUserData.lang
}