import { ClockCircleOutlined, DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons'
import { BandVM } from '@kazoo/shared'
import { Button, Dropdown, Menu, Modal, Tag } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    band: BandVM
    onEdit?: (band: BandVM) => void
    onDelete?: (band: BandVM) => void
}

export const BandCard: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const { band, onEdit, onDelete } = props

    const handleDelete = () => {
        if (!onDelete) {
            return
        }

        Modal.confirm({
            title: `${t('delete', { defaultValue: 'Delete' })} "${band.name}"`,
            content: t('delete_band_confirm', { defaultValue: 'Are you sure you want to delete this band?' }),
            okText: t('yes'),
            cancelText: t('no'),
            onOk: () => onDelete(band)
        })
    }

    const actionsMenu = (
        <Menu>
            <Menu.Item
                className="flex items-center"
                onClick={onEdit ? () => onEdit(band) : undefined}
                key="1"
                icon={<EditOutlined />}
            >
                {t('Edit')}
            </Menu.Item>
            {onDelete && (
                <Menu.Item className="flex items-center" key="2" icon={<DeleteOutlined />} onClick={handleDelete}>
                    {t('Delete')}
                </Menu.Item>
            )}
        </Menu>
    )

    return (
        <div className="p-4 mb-4 border-b border-gray-300 border-solid last:border-b-0 last:mb-0">
            <div className="flex items-center">
                <p>{band.name}</p>
                <span className="text-xs ml-1 text-gray-500">
                    ({`${band.members.length} ${t('members', { defaultValue: 'members' })}`})
                </span>
                {onEdit && (
                    <div className="ml-auto">
                        <Dropdown overlay={actionsMenu}>
                            <Button className="ml-2" type="ghost">
                                {t('actions', { defaultValue: 'Actions' })}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                )}
            </div>
            <div className="flex flex-col justify-center">
                {band.members.map((member) => (
                    <div key={member.id} className="flex items-center mt-2">
                        <span className="text-xs">
                            {member.registered ? (
                                <Tag color="green">{t('registered')}</Tag>
                            ) : (
                                <Tag color="orange">{t('invited')}</Tag>
                            )}
                            {member.name ?? member.id}
                            <span className="ml-2">
                                ({member.instruments.map((instrument) => instrument.name).join(', ')})
                            </span>
                        </span>
                    </div>
                ))}
                {onEdit && (
                    <div className="flex items-center mt-2 text-xs text-gray-600">
                        <ClockCircleOutlined className="mr-1" />
                        {`${t('created', { defaultValue: 'Created' })} ${moment(band.createdOn).fromNow()}`}
                    </div>
                )}
            </div>
        </div>
    )
}
