import { Lang } from '@kazoo/shared'
import cn from 'classnames'
import moment from 'moment'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../hooks/use-auth'
import FRFlag from '../../images/fr_flag.png'
import GBFlag from '../../images/gb_flag.png'
import { api } from '../../services/api'
import { getCurrentLang } from '../../utils/lang-utils'

export const LangSwitcher = () => {
    const { i18n } = useTranslation()
    const { user } = useAuth()
    const currentLocale = getCurrentLang(i18n)

    const switchToFrench = useCallback(() => {
        moment.locale(Lang.FR)
        i18n.changeLanguage(Lang.FR)
        if (user.id) {
            api.user.updateLang(Lang.FR)
        }
    }, [i18n, user])

    const switchToEnglish = useCallback(() => {
        moment.locale(Lang.EN)
        i18n.changeLanguage(Lang.EN)
        if (user.id) {
            api.user.updateLang(Lang.EN)
        }
    }, [i18n, user])

    return (
        <div className="flex items-center space-x-2">
            <button onClick={switchToFrench}>
                <img
                    className={cn({
                        'filter grayscale': currentLocale === Lang.EN
                    })}
                    alt="fr-flag"
                    src={FRFlag}
                />
            </button>
            <button onClick={switchToEnglish}>
                <img
                    className={cn({
                        'filter grayscale': currentLocale === Lang.FR
                    })}
                    alt="gb-flag"
                    src={GBFlag}
                />
            </button>
        </div>
    )
}
