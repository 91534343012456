import { CreditType } from '@kazoo/shared'
import React from 'react'
import { useAsync } from 'react-async-hook'
import { useTranslation } from 'react-i18next'
import { AccentedHeadline } from '../../components/accented-headline/accented-headline'
import { api } from '../../services/api'
import { PriceCard } from './price-card'

interface ChooseCreditProps {
    type: CreditType
    handlePurchase: (id: string, numOfHours: number) => void
}

export const ChooseCredit: React.FC<ChooseCreditProps> = ({ type, handlePurchase }) => {
    const { result: packsResponse } = useAsync(api.hourPacks.getAll, [])
    const { t } = useTranslation()

    if (!packsResponse) {
        return null
    }

    const hourPacks = packsResponse.data
        .filter((p) => p.type === type && p.quantity >= 5)
        .sort((a, b) => a.quantity - b.quantity)

    const singleHourPacks = packsResponse.data
        .filter((p) => p.type === type && p.quantity < 5)
        .sort((a, b) => a.quantity - b.quantity)

    return (
        <div className="flex flex-col">
            <AccentedHeadline title={t('buy_individual_hours', { defaultValue: 'Buy individual hours' })} />

            <div className="grid grid-cols-2 gap-4 mb-12 md:grid-cols-4 md:gap-8">
                {singleHourPacks.map((pack) => (
                    <PriceCard
                        key={pack.id}
                        price={pack.price}
                        hours={pack.quantity}
                        onClick={() => handlePurchase(pack.id, pack.quantity)}
                    />
                ))}
            </div>

            <AccentedHeadline
                title={t('buy_pack_save_money', { defaultValue: 'Or save money by buying a pack! 😎🤟' })}
            />

            <div className="grid grid-cols-1 gap-12  md:grid-cols-3 md:gap-8">
                {hourPacks?.map((pack) => (
                    <PriceCard
                        key={pack.id}
                        basePrice={pack.price}
                        price={pack.price}
                        hours={pack.quantity}
                        discountPercentage={pack.discount}
                        bestDeal={Number.parseInt(pack.discount) === 25}
                        mostPopular={Number.parseInt(pack.discount) === 15}
                        onClick={() => handlePurchase(pack.id, pack.quantity)}
                    />
                ))}
            </div>
            <p className="mt-4 text-center opacity-75">
                {t('new_tab_info', { defaultValue: 'A new tab will open to complete the payment' })}
            </p>
        </div>
    )
}
