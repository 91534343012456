export const loadWalleeScript = (scriptURL: string, callback: () => void) => {
    const existingScript = document.getElementById('walleePaymentScript')
    if (existingScript) {
        existingScript?.parentNode?.removeChild(existingScript)
    }

    const script = document.createElement('script')
    script.src = scriptURL
    script.id = 'walleePaymentScript'
    document.body.appendChild(script)
    script.onload = () => {
        callback()
    }
}

export const openWalleeLightbox = () => {
    ;(window as any)?.LightboxCheckoutHandler?.startPayment(null, (error: any) => {
        console.error(error)
        alert('An error occurred during the initialization of the payment. Please try again')
    })
}
