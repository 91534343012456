import {
    BandVM,
    Blocks,
    BlockSelection,
    BookingType,
    deleteBlock,
    getBlockKey,
    getHourRange,
    getTotalHoursFromBlocks,
    mergeBlocks,
    updateBlocks
} from '@kazoo/shared'
import { Button } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { BookingSummary } from '../../components/booking-summary/booking-summary'
import { Calendar } from '../../components/calendar/calendar'
import { useAuth } from '../../hooks/use-auth'
import { goToBuyCredits } from '../../utils/routing'
import { BookWizardParams } from './book-wizard'

const hourRange = getHourRange()

interface Step2CalendarProps {
    userBands?: BandVM[]
    userBlocks: Blocks
    weekRange: moment.Moment[]
    setWeekRange: (weekRange: moment.Moment[]) => void
    handleNext: () => void
    setUserBlocks: (blocks: Blocks) => void
    existingBookings?: Blocks
}

export const Step2Calendar: React.FC<Step2CalendarProps> = ({
    userBands,
    userBlocks,
    weekRange,
    setWeekRange,
    setUserBlocks,
    handleNext: handleBook,
    existingBookings
}) => {
    const { user } = useAuth()
    const { t } = useTranslation()

    const { band: bandHours, lesson: lessonHours, solo: soloHours, duo: duoHours } = getTotalHoursFromBlocks(userBlocks)
    const { room, bookingType: paramsBookingType } = useParams<BookWizardParams>()
    const [bookingType, bandId] = (paramsBookingType || '').split('_')
    const band = userBands?.find((b) => b.id === bandId)
    const allBlocks = useMemo(() => mergeBlocks(existingBookings, userBlocks), [existingBookings, userBlocks])

    const noBookingsYet = Object.keys(userBlocks).length === 0
    const notEnoughCredits =
        bandHours > user.bandHours ||
        lessonHours > user.lessonHours ||
        soloHours > user.soloHours ||
        duoHours > user.duoHours
    const bookNowButtonDisabled = noBookingsYet || notEnoughCredits

    const onBlockSelect = useCallback(
        (blockSelection: BlockSelection) => {
            setUserBlocks(
                updateBlocks({
                    blocks: userBlocks,
                    blockSelection,
                    bookingType: bookingType as BookingType,
                    band,
                    room: parseInt(room ?? '1')
                })
            )
        },
        [band, bookingType, room, userBlocks, setUserBlocks]
    )

    const onBlockDelete = useCallback(
        (blockSelection: BlockSelection) => {
            setUserBlocks(
                deleteBlock(
                    userBlocks,
                    getBlockKey(blockSelection.year, blockSelection.dayOfYear, blockSelection.startBlock),
                    blockSelection.id
                )
            )
        },
        [userBlocks, setUserBlocks]
    )

    const onWeekRangeChange = (weekRange: moment.Moment[]) => {
        setWeekRange(weekRange)
    }

    if (!room || !paramsBookingType) {
        return null
    }

    return (
        <div className="md:grid md:grid-cols-12 gap-4">
            <div className="md:col-span-8">
                <Calendar
                    room={parseInt(room)}
                    blocks={allBlocks}
                    hourRange={hourRange}
                    weekRange={weekRange}
                    onWeekRangeChange={onWeekRangeChange}
                    onBlockSelect={onBlockSelect}
                    onBlockDelete={onBlockDelete}
                />
            </div>
            <div className="md:col-span-4 mt-8 md:mt-0">
                <BookingSummary blocks={userBlocks} onBlockDelete={onBlockDelete} />
                <div className="mt-10">
                    <Button onClick={handleBook} type="primary" disabled={bookNowButtonDisabled}>
                        {t('next', { defaultValue: 'Next' })}
                    </Button>
                </div>
                {notEnoughCredits && (
                    <div className="mt-4">
                        <p className="mt-2 text-sm text-center text-red-500">
                            {t('not_enough_hour_credits', { defaultValue: 'Not enough hour credits' })}
                        </p>
                        <Link to={goToBuyCredits()}>
                            <Button className="mt-2" type="primary">
                                {t('no_credits_buy_more_button')}
                            </Button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}
