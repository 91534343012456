import { PhoneNumberDto } from '@kazoo/shared'
import { TFunction } from 'i18next'
import * as yup from 'yup'

export const phoneValidator = (t: TFunction): any => yup
    .object<PhoneNumberDto>({
        countryPrefix: yup.number().required(),
        countryCode: yup.string().required(),
        phoneNumber: yup
            .string()
            .min(5, t('Please enter a valid phone number'))
            .required(t('Please enter your phone number')),
        formattedNumber: yup.string().required()
    })
    .required(t('Please enter your phone number'))
