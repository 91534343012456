import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { LoginUserDto, toTitleCase } from '@kazoo/shared'
import useDocumentTitle from '@tanem/use-document-title'
import { Modal } from 'antd'
import { FormikProvider, useFormik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { appConfig } from '../../app-config'
import { useAuth } from '../../hooks/use-auth'
import KazooLogo from '../../images/logo-white.png'
import { LoginFormData } from '../../types'

export const Login: React.FC = () => {
    useDocumentTitle('Kazoo - Login')
    const { t, i18n } = useTranslation()
    const [, setError] = useState(null)
    const { login } = useAuth()

    const LoginSchema = useMemo(
        () =>
            yup.object().shape({
                email: yup
                    .string()
                    .required(t('login_email_required', { defaultValue: 'Please enter your email' }))
                    .email(t('login_invalid_email', { defaultValue: 'Please enter a valid email address' })),
                password: yup
                    .string()
                    .required(t('login_password_required', { defaultValue: 'Please enter your password' }))
            }),
        [t]
    )

    const onSubmit = async (data: LoginFormData) => {
        try {
            await login(data)
        } catch (error) {
            // TODO: do something with this error...
            setError(error)
        }
    }

    const formik = useFormik<LoginUserDto>({
        onSubmit,
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema
    })

    const { errors, setFieldTouched } = formik

    i18n.on('languageChanged', () => {
        Object.keys(errors).forEach((fieldName) => {
            setFieldTouched(fieldName)
        })
    })

    return (
        <FormikProvider value={formik}>
            <>
                <img className="w-32 mx-auto mt-4 lg:mt-8 lg:w-64" src={KazooLogo} alt="kazoo logo" />
                <Modal footer={null} mask={false} centered={true} closable={false} maskClosable={false} visible={true}>
                    <p className="text-2xl text-center">{toTitleCase(t('login'))}</p>
                    <Form className="mt-4">
                        <Form.Item name="email" className="mb-4">
                            <Input
                                name="email"
                                data-test="emailInput"
                                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Email"
                                type="email"
                            />
                        </Form.Item>
                        <Form.Item name="password" className="mb-4">
                            <Input
                                name="password"
                                data-test="passwordInput"
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <div className="flex flex-col items-center">
                            <SubmitButton data-test="loginSubmit" className="w-4/6 lg:w-2/5">
                                {toTitleCase(t('login'))}
                            </SubmitButton>
                            <p className="mt-3 text-xs">{t("Don't have an account?")}</p>
                            <Link className="text-xs font-bold text-blue-500" to={appConfig.pathnames.register}>
                                {t('create_one_now')}
                            </Link>
                        </div>
                    </Form>
                </Modal>
            </>
        </FormikProvider>
    )
}
