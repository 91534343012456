import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatPriceCHF, formatPricePerHourWithDiscount } from '../../utils/format-price'
import styles from './price-card.module.scss'

interface PriceCardProps {
    price: string
    hours: number
    discountPercentage?: string
    basePrice?: string
    mostPopular?: boolean
    bestDeal?: boolean
    onClick: () => void
}

export const PriceCard: React.FC<PriceCardProps> = ({
    price,
    hours,
    discountPercentage,
    mostPopular,
    bestDeal,
    onClick,
    basePrice
}) => {
    const { t } = useTranslation()

    let rawPrice = 1;
    if(!!discountPercentage && !!basePrice) {
        rawPrice = (Number.parseFloat(basePrice)/(100-Number.parseFloat(discountPercentage)))*100
    }

    return (
        <div>
            <div className="relative w-full">
                <div className="flex flex-col transition duration-100 bg-white rounded-lg shadow group">
                    <div className="w-full px-8 py-8 rounded-t-lg card-section-1">
                        <h3 className="mx-auto font-semibold text-center text-blue-500">{discountPercentage ? 'Pack ' : ''}{hours}h</h3>
                        <div className="text-center text-blue-500">
                            <span className="">CHF</span>
                            <span className="ml-1 text-5xl font-semibold tracking-tighter">
                                {formatPriceCHF(price)}
                            </span>
                        </div>
                        {basePrice && discountPercentage && (
                            <div className="text-xs text-center text-blue-400">
                                <span className="font-semibold">
                                    CHF&nbsp;{formatPricePerHourWithDiscount(basePrice, discountPercentage, hours)}
                                </span>
                                &nbsp;
                                {t('per_hour', { defaultValue: 'per hour' })}
                            </div>
                        )}
                    </div>
                    {mostPopular && (
                        <div className={styles.ribbon}>
                            <span>{t('most_popular', { defaultValue: 'Most popular' })}</span>
                        </div>
                    )}
                    {bestDeal && (
                        <div className={styles.ribbonGreen}>
                            <span>{t('best_deal', { defaultValue: 'Best deal' })}</span>
                        </div>
                    )}
                    <div className="flex flex-col items-center justify-center w-full h-full py-6 bg-blue-200 rounded-b-lg">
                        <button
                            onClick={onClick}
                            className="w-5/6 py-2 mt-2 font-semibold text-center text-blue-500 transition duration-100 transform bg-white rounded hover:shadow-lg"
                        >
                            {discountPercentage ? (
                                <>
                                    <span className="text-green-500">
                                        {t('Save')}&nbsp;{Number.parseFloat(discountPercentage)}%
                                    </span>
                                    <span className="ml-1">
                                        = CHF{' '}

                                        {formatPriceCHF(
                                            (
                                                (rawPrice * Number.parseFloat(discountPercentage)) /
                                                100
                                            ).toString()
                                        )}
                                    </span>
                                </>
                            ) : (
                                t('buy', { defaultValue: 'Buy' })
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
