import cn from 'classnames'
import React from 'react'

interface Props {
    className?: string
    title: string
}

export const AccentedHeadline: React.FC<Props> = ({ className, title }) => {
    return (
        <div className="mb-4">
            <p className="text-xl opacity-80">{title}</p>
            <div className={cn('w-8 h-1 bg-yellow-400 mt-1', className)} />
        </div>   
    )
}
