import { PhoneNumberDto, UserVM } from '@kazoo/shared'
import { Input, Select } from 'antd'
import { InputProps } from 'antd/lib/input'
import { FieldProps } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { countries, ICountry } from './countries'
import styles from './styles.module.scss'

interface PhoneInputProps extends FieldProps<Partial<PhoneNumberDto> | undefined, Partial<UserVM>> {
    inputProps?: InputProps
}

const handleFilterOption = (inputValue: string, option?: { key?: string | number }): boolean => {
    const optionName = option?.key as string | undefined
    if (optionName) {
        return optionName.toLowerCase().includes(inputValue.toLowerCase())
    }
    return true
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
    inputProps,
    field: { onBlur, name, value },
    form: { setFieldValue, setFieldTouched }
}) => {
    const defaultCountry: ICountry | undefined = useMemo(() => {
        return countries.find((c) => c.code === 'CH')
    }, [])
    const [country, setCountry] = useState<ICountry | undefined>(defaultCountry)
    const [phone, setPhone] = useState<string | undefined>()
    const phoneRef = useRef<Input>(null)

    useEffect(() => {
        if (value) {
            setCountry(countries.find((c) => c.code === value.countryCode))
            setPhone(value.phoneNumber)
        }
    }, [value])

    const triggerChange = (phoneNumber?: string, country?: ICountry) => {
        const result: PhoneNumberDto = {
            phoneNumber: phoneNumber || '',
            countryPrefix: country ? Number(country.phoneCode) : -1,
            countryCode: country ? country.code : '',
            formattedNumber: `+${country?.phoneCode || ''}${phoneNumber || ''}`
        }
        setFieldValue(name, result)
        setFieldTouched(name)
    }

    const handleCountryChange = (code: string) => {
        const c = countries.find((c) => c.code === code)
        if (!c) {
            throw new Error(`Country not found: ${code}`)
        }
        setCountry(c)
        triggerChange(phone, c)
    }

    const handlePhoneChange = () => {
        if (phoneRef?.current) {
            const value = phoneRef.current?.input.value
            setPhone(value)
            triggerChange(value, country)
        }
    }

    return (
        <Input.Group compact>
            <Select
                showSearch
                value={country?.code}
                style={{ width: '100px', borderRight: 'none' }}
                dropdownMatchSelectWidth={false}
                optionLabelProp="label"
                optionFilterProp="children"
                onBlur={() => onBlur(name)}
                onChange={handleCountryChange}
                filterOption={handleFilterOption}
            >
                {countries.map((c) => (
                    <Select.Option
                        key={c.name}
                        value={c.code}
                        label={
                            <div className={styles.selectInfo}>
                                <div>{c.emoji}&nbsp;</div>
                                <div>+{c.phoneCode}</div>
                            </div>
                        }
                    >
                        <div className={styles.selectInfo}>
                            <div>{c.emoji}&nbsp;</div>
                            <div>
                                {c.name} +{c.phoneCode}
                            </div>
                        </div>
                    </Select.Option>
                ))}
            </Select>
            <Input
                style={{ width: 'calc(100% - 100px)' }}
                ref={phoneRef}
                onChange={handlePhoneChange}
                value={value?.phoneNumber}
                type="tel"
                {...inputProps}
            />
        </Input.Group>
    )
}
