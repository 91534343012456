import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { appConfig } from './app-config';

const isProduction = process.env.NODE_ENV === 'production';

const locizeOptions = {
  projectId: appConfig.locizeProjectId,
  apiKey: appConfig.locizeApiKeyDevOnly,
  referenceLng: appConfig.referenceLang
};

if (!isProduction) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  i18n.use(LastUsed);
}

i18n
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isProduction,
    fallbackLng: 'en',
    saveMissing: !isProduction, // you should not use saveMissing in production
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    nsSeparator: false,
    updateMissing: !isProduction,
    nonExplicitSupportedLngs: true,
    keySeparator: false,
    appendNamespaceToMissingKey: false,
    backend: locizeOptions,
    locizeLastUsed: locizeOptions
  });

export default i18n;