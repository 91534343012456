import { Blocks, BookingType, getWeekRange, Room, roomName } from '@kazoo/shared'
import useBreakpoint from '@w11r/use-breakpoint'
import { notification, Steps } from 'antd'
import { AxiosError } from 'axios'
import React, { useCallback, useState } from 'react'
import { useAsync } from 'react-async-hook'
import { useTranslation } from 'react-i18next'
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { appConfig } from '../../app-config'
import { BookingTypeIndicator } from '../../components/room-usage-indicator/room-usage-indicator'
import { useAuth } from '../../hooks/use-auth'
import { api } from '../../services/api'
import { goToBookCalendar, goToBookComments, goToBookRoom, goToBookSuccess } from '../../utils/routing'
import { Step0BookingType } from './step-0-booking-type'
import { Step1Room } from './step-1-room'
import { Step2Calendar } from './step-2-calendar'
import { Step3Comments } from './step-3-comments'
import { Step4Success } from './step-4-success'

export interface BookWizardParams {
    bookingType?: string
    room?: string
}

const getCurrentStep = (params: BookWizardParams, pathname: string): number => {
    if (pathname.includes('calendar')) {
        return 2
    } else if (pathname.includes('success')) {
        return -1
    } else if (params.bookingType && !params.room) {
        return 1
    } else if (pathname.includes('comments')) {
        return 3
    } else {
        return 0
    }
}

export const BookWizard: React.FC = () => {
    const { result: bandsResponse } = useAsync(api.bands.getAll, [])
    const userBands = bandsResponse?.data
    const [userBlocks, setUserBlocks] = useState<Blocks>({})
    const [weekRange, setWeekRange] = useState<moment.Moment[]>(getWeekRange())
    const { result: existingBookingsResponse, execute: refreshExistingBookings } = useAsync(api.bookings.getAll, [])
    const { refreshUser } = useAuth()
    const history = useHistory()
    const params = useParams<BookWizardParams>()
    const { isMobile } = useBreakpoint()

    const { t } = useTranslation()

    const handleBook = useCallback(
        (comment?: string) => {
            api.bookings
                .book({ blocks: userBlocks, comment })
                .then(() => {
                    refreshUser()
                    setUserBlocks({})
                    notification.success({
                        message: t('book_success', { defaultValue: 'Your booking was successful!' })
                    })
                    history.push(goToBookSuccess())
                })
                .catch((error: AxiosError) => {
                    notification.warn({ message: error.response?.data?.message })
                })
                .finally(() => {
                    refreshExistingBookings()
                })
        },
        [refreshExistingBookings, refreshUser, t, userBlocks, history]
    )

    const [bookingType, bandId] = (params.bookingType || '').split('_')
    const bandName = userBands?.find((b) => b.id === bandId)?.name
    const room = parseInt(params.room || '') as Room
    const currentStep = getCurrentStep(params, history.location.pathname)

    return (
        <>
            {currentStep >= 0 && (
                <div className="mb-8">
                    <Steps
                        size={isMobile ? 'small' : 'default'}
                        direction={isMobile ? 'vertical' : 'horizontal'}
                        current={currentStep}
                    >
                        <Steps.Step
                            key={0}
                            subTitle={
                                <BookingTypeIndicator bandName={bandName} bookingType={bookingType as BookingType} />
                            }
                            title={
                                <Link to={appConfig.pathnames.book}>
                                    <span className="text-base whitespace-normal">
                                        {t('book-wizard_booking_type', { defaultValue: 'Booking Type' })}
                                    </span>
                                </Link>
                            }
                        />
                        <Steps.Step
                            key={1}
                            subTitle={room ? <div className="leading-none">{roomName[room]}</div> : undefined}
                            title={
                                bookingType ? (
                                    <Link to={goToBookRoom(params.bookingType)}>
                                        <span className="text-base whitespace-normal">
                                            {t('book-wizard_room', { defaultValue: 'Room' })}
                                        </span>
                                    </Link>
                                ) : (
                                    <span className="text-base whitespace-normal">
                                        {t('book-wizard_room', { defaultValue: 'Room' })}
                                    </span>
                                )
                            }
                        />
                        <Steps.Step
                            key={2}
                            title={
                                params.room ? (
                                    <Link to={goToBookCalendar(params, params.room)}>
                                        <span className="text-base whitespace-normal">
                                            {t('book-wizard_calendar', { defaultValue: 'Date and time' })}
                                        </span>
                                    </Link>
                                ) : (
                                    <span className="text-base whitespace-normal">
                                        {t('book-wizard_calendar', { defaultValue: 'Date and time' })}
                                    </span>
                                )
                            }
                        />
                        <Steps.Step
                            key={3}
                            title={
                                <span className="text-base whitespace-normal">
                                    {t('book-wizard_comments', { defaultValue: 'Comments' })}
                                </span>
                            }
                        />
                    </Steps>
                </div>
            )}
            <Switch>
                <Route
                    exact
                    path={appConfig.pathnames.book}
                    component={() => <Step0BookingType userBands={userBands} />}
                />
                <Route exact path={appConfig.pathnames.bookSuccess} component={Step4Success} />
                <Route exact path={`${appConfig.pathnames.book}/:bookingType`} component={() => <Step1Room />} />
                <Route
                    exact
                    path={`${appConfig.pathnames.book}/:bookingType/:room/calendar`}
                    component={() => (
                        <Step2Calendar
                            weekRange={weekRange}
                            setWeekRange={setWeekRange}
                            existingBookings={existingBookingsResponse?.data}
                            userBands={userBands}
                            handleNext={() => history.push(goToBookComments(params))}
                            userBlocks={userBlocks}
                            setUserBlocks={setUserBlocks}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${appConfig.pathnames.book}/:bookingType/:room/comments`}
                    component={() => <Step3Comments handleBook={handleBook} userBlocks={userBlocks} />}
                />
            </Switch>
        </>
    )
}
