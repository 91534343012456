import { HomeOutlined, LockOutlined } from '@ant-design/icons'
import {
    BlockDetailsWithStart,
    Blocks,
    BlockSelection,
    BookingType,
    defaultBookingDuration,
    filterBlocksByRoom,
    getBookingDisplayTime,
    getDayBlocks,
    halfHourBlocks, momentTimezone,
    Room,
    roomName
} from "@kazoo/shared";
import { Popconfirm } from 'antd'
import cn from 'classnames'
import { inRange } from 'lodash'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import styles from './styles.module.scss'
import "moment-timezone";
const moment = extendMoment(Moment)
interface Props {
    room: Room
    blocks: Blocks
    weekRange: moment.Moment[]
    hourRange: moment.Moment[]
    onBlockSelect: (selection: BlockSelection) => void
    onBlockDelete: (selection: BlockSelection) => void
}

const now = moment.tz(momentTimezone)

export const CalendarCols: React.FC<Props> = ({ weekRange, onBlockSelect, onBlockDelete, hourRange, blocks, room }) => {
    const { t } = useTranslation()

    const handleClick = useCallback(
        (selection: BlockSelection) => () => {
            onBlockSelect(selection)
        },
        [onBlockSelect]
    )

    const renderDescription = useCallback(
        (blockDetails: BlockDetailsWithStart) => {
            const { deletable, owner, bandDetails, bookingType } = blockDetails

            let description
            if (bookingType === BookingType.CLEANING) {
                description = t('cleaning', { defaultValue: 'Cleaning' })
            } else if (owner) {
                if (bandDetails) {
                    description = bandDetails.name
                } else if (bookingType === BookingType.LESSON) {
                    description = t('lesson', { defaultValue: 'Lesson' })
                } else if (bookingType === BookingType.DUO) {
                    description = t('duo')
                } else if (bookingType === BookingType.SOLO) {
                    description = t('solo_practice', { defaultValue: 'Solo practice' })
                }
            }

            return (
                <div className="flex items-baseline" style={{ marginTop: 1 }}>
                    {!deletable && <LockOutlined className="mr-1 text-xxs" />}
                    <span className="text-xs font-bold">{description}</span>
                </div>
            )
        },
        [t]
    )

    const handleBlockDelete = useCallback(
        (selection: BlockSelection) => () => {
            onBlockDelete(selection)
        },
        [onBlockDelete]
    )

    const filteredBlocks = useMemo(() => filterBlocksByRoom(blocks, room), [blocks, room])
    const startHourBlockOffset = useMemo(() => hourRange[0].hour() * halfHourBlocks.length, [hourRange])
    const numOfHours = useMemo(() => hourRange.length, [hourRange.length])

    return (
        <div className="grid w-full grid-cols-7">
            {weekRange.map((dayObj, dayIdx) => (
                <div key={dayObj.day()} className="relative flex flex-col w-full">
                    {hourRange.map((hourObj, hourIdx) => (
                        <div key={hourIdx} className="flex flex-col w-full h-16">
                            {halfHourBlocks.map((_, halfHourIdx) => {
                                const inThePast =
                                  dayObj.isBefore(now, 'day') ||
                                  (dayObj.dayOfYear() === now.dayOfYear() && hourObj.hour() < now.hour())
                                const lastBlock = hourIdx === numOfHours - 1 && halfHourIdx === 1
                                const disabled = inThePast || lastBlock

                                return (
                                    <button
                                        key={halfHourIdx}
                                        disabled={disabled}
                                        className={cn('h-8 border', {
                                            'border-r-0': dayIdx !== 6,
                                            'border-t-0': hourIdx !== 0 || (hourIdx === 0 && halfHourIdx === 1),
                                            'bg-white border-gray-200': !disabled,
                                            'bg-gray-100 border-gray-300': disabled,
                                            'cursor-not-allowed': disabled,
                                            [styles.blockButton]: !disabled,
                                            [styles.nowIndicator]:
                                                now.day() === dayObj.day() &&
                                                inRange(hourObj.diff(now, 'minutes') + halfHourIdx * 30, -29, 0)
                                        })}
                                        onClick={handleClick({
                                            id: uuidv4(),
                                            room,
                                            year: dayObj.year(),
                                            dayOfYear: dayObj.dayOfYear(),
                                            startBlock: hourObj.hour() * halfHourBlocks.length + halfHourIdx,
                                            duration: defaultBookingDuration
                                        })}
                                    />
                                )
                            })}
                        </div>
                    ))}
                    {getDayBlocks(filteredBlocks, dayObj).map((blockDetails) => {
                        const { startBlock, duration, deletable, bookingType, room, owner, id } = blockDetails
                        return (
                            <div
                                key={startBlock}
                                className="absolute flex w-full"
                                style={{
                                    top: `calc(2rem * ${startBlock - startHourBlockOffset})`,
                                    height: `calc(2rem * ${duration})`,
                                    paddingBottom: 2
                                }}
                            >
                                <div className="relative w-full">
                                    <div className="absolute inset-0 h-8 cursor-not-allowed" style={{ top: '-2rem' }} />
                                    <Popconfirm
                                        disabled={!deletable}
                                        placement="top"
                                        title={t('cancel_booking', { defaultValue: 'Cancel booking?' })}
                                        onConfirm={
                                            deletable
                                                ? handleBlockDelete({
                                                      id,
                                                      room,
                                                      year: dayObj.year(),
                                                      dayOfYear: dayObj.dayOfYear(),
                                                      startBlock: startBlock,
                                                      duration
                                                  })
                                                : undefined
                                        }
                                        okText={t('yes', { defaultValue: 'Yes' })}
                                        cancelText={t('no', { defaultValue: 'No' })}
                                    >
                                        <div className="h-full">
                                            <button
                                                disabled={!deletable}
                                                style={{ padding: 2 }}
                                                className={cn(
                                                    'group z-2 relative h-full w-11/12 border rounded mx-auto text-left leading-none flex flex-col text-white',
                                                    {
                                                        'border-blue-600 blue-500-stripes':
                                                            bookingType === BookingType.CLEANING,
                                                        'bg-blue-500 border-blue-700':
                                                            deletable && owner && bookingType === BookingType.BAND,
                                                        'hover:bg-blue-400':
                                                            deletable && bookingType === BookingType.BAND,
                                                        'bg-black':
                                                            deletable && owner && bookingType === BookingType.SOLO,
                                                        ' hover:bg-gray-800':
                                                            deletable && bookingType === BookingType.SOLO,
                                                        'bg-pink-400':
                                                            deletable && owner && bookingType === BookingType.DUO,
                                                        ' hover:bg-pink-300':
                                                            deletable && bookingType === BookingType.DUO,
                                                        'bg-yellow-500 border-orange-600':
                                                            deletable && owner && bookingType === BookingType.LESSON,
                                                        'hover:bg-yellow-400':
                                                            deletable && bookingType === BookingType.LESSON,
                                                        'bg-red-500 border-red-600 cursor-not-allowed':
                                                            !owner && !deletable,
                                                        'bg-green-400 border-green-500 cursor-not-allowed':
                                                            owner && !deletable
                                                    }
                                                )}
                                            >
                                                <div className="tracking-tighter whitespace-nowrap text-xxs">
                                                    {getBookingDisplayTime(startBlock, duration).label}
                                                </div>
                                                {deletable && (
                                                    <div
                                                        style={{ marginTop: 2 }}
                                                        className="flex items-center tracking-tighter text-xxs"
                                                    >
                                                        <HomeOutlined />
                                                        <span className="ml-1 font-bold">{roomName[room]}</span>
                                                    </div>
                                                )}

                                                {renderDescription(blockDetails)}
                                            </button>
                                        </div>
                                    </Popconfirm>
                                </div>
                            </div>
                        )
                    })}
                </div>
            ))}
        </div>
    )
}
