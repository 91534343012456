import {
    CalendarOutlined,
    CreditCardOutlined,
    HistoryOutlined,
    HomeOutlined,
    QuestionOutlined,
    TeamOutlined
} from '@ant-design/icons'
import { Menu } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { appConfig } from '../../app-config'
import { useAuth } from '../../hooks/use-auth'
import AppLogo from '../../images/logo-white.png'
import { HowItWorks } from '../../pages/how-it-works/how-it-works'

export const DesktopMenu = () => {
    const { user } = useAuth()
    const { pathname } = useLocation()
    const { t } = useTranslation()
    const [showHowItWorks, setShowHowItWorks] = useState(user.firstLogin)

    const startHowItWorks = useCallback(() => setShowHowItWorks(true), [])
    const stopHowItWorks = useCallback(() => setShowHowItWorks(false), [])

    return (
        <>
            <Sider collapsible width={240}>
                <Menu theme="dark" mode="inline" selectedKeys={[pathname]}>
                    <div className="">
                        <img src={AppLogo} alt="kazoo logo" className="w-3/4 mx-auto my-8" />
                    </div>
                    <Menu.Item className="flex items-center" data-test="menuHome" key={appConfig.pathnames.home}>
                        <HomeOutlined />
                        <span>{t('menu_home', { defaultValue: 'Home' })}</span>
                        <Link to={appConfig.pathnames.home} />
                    </Menu.Item>
                    <Menu.Item
                        className="flex items-center"
                        data-test="menuHowItWorks"
                        onClick={startHowItWorks}
                        key={'howItWorks'}
                    >
                        <QuestionOutlined />
                        <span>{t('menu_how_it_works', { defaultValue: 'How it works' })}</span>
                    </Menu.Item>
                    <Menu.Item
                        className="flex items-center"
                        data-test="menuBuy"
                        id={appConfig.howItWorksSelectors.buyCreditHours}
                        key={appConfig.pathnames.buyCreditHours}
                    >
                        <CreditCardOutlined />
                        <span>{t('menu_buy', { defaultValue: 'Buy' })}</span>
                        <Link to={appConfig.pathnames.buyCreditHours} />
                    </Menu.Item>
                    <Menu.Item
                        className="flex items-center"
                        data-test="menuBook"
                        id={appConfig.howItWorksSelectors.book}
                        key={appConfig.pathnames.book}
                    >
                        <CalendarOutlined />
                        <span>{t('menu_book', { defaultValue: 'Book' })}</span>
                        <Link to={appConfig.pathnames.book} />
                    </Menu.Item>
                    <Menu.Item className="flex items-center" data-test="menuBands" key={appConfig.pathnames.bands}>
                        <TeamOutlined />
                        <span>{t('menu_bands', { defaultValue: 'Bands' })}</span>
                        <Link to={appConfig.pathnames.bands} />
                    </Menu.Item>
                    <Menu.Item
                        id={appConfig.howItWorksSelectors.manageBookings}
                        className="flex items-center"
                        data-test="history"
                        key={appConfig.pathnames.allBookings}
                    >
                        <HistoryOutlined />
                        <span>{t('menu_bookings', { defaultValue: 'Bookings' })}</span>
                        <Link to={appConfig.pathnames.allBookings} />
                    </Menu.Item>
                </Menu>
            </Sider>
            <HowItWorks run={showHowItWorks} stop={stopHowItWorks} />
        </>
    )
}
