import {
    BandVM,
    Blocks,
    BookingDto,
    BookingVM,
    CancelBookingDto,
    CreateBandDto,
    CreateBandResponse,
    CreateUserDto,
    CreditPack,
    CreditType,
    DeleteBandDto,
    EditBandDto,
    HourPackVM,
    InstrumentVM,
    Lang,
    LoginStatus,
    SimpleResponse,
    UserVM
} from '@kazoo/shared'
import axios from 'axios'
import { appConfig } from '../app-config'
import { LoginFormData } from '../types'
import { JwtStore } from './jwt-store'

export const api = {
    user: {
        loginWithJwt: (jwt: string) =>
            axios.post<LoginStatus>(`${appConfig.apiUrl}/auth/jwt-login`, null, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            }),
        get: (userId: string) =>
            axios.get<UserVM>(`${appConfig.apiUrl}/user/${userId}`, {
                headers: JwtStore.getAuthHeaders()
            }),
        login: (data: LoginFormData) => axios.post<LoginStatus>(`${appConfig.apiUrl}/auth/login`, data),
        create: (data: CreateUserDto) => axios.post(`${appConfig.apiUrl}/user`, data),
        updateLang: (lang: Lang) => axios.post(`${appConfig.apiUrl}/user/lang`, {lang}, {
            headers: JwtStore.getAuthHeaders()
        }),
        // update: (data: UserVM): Promise<UserVM> =>
        //     axios
        //         .put<UserVM>(`${appConfig.apiUrl}/user/${data.id}`, data, {
        //             headers: JwtStore.getAuthHeaders()
        //         })
        //         .then(({ data }) => {
        //             void notification.success({ message: t('Update successful') })
        //             return data
        //         })
        //         .catch((error: AxiosError) => {
        //             // Rollbar.error(error)
        //             void notification.error({ message: error.message || t('Update unsuccessful. Please try again.') })
        //             throw error
        //         })
    },
    bookings: {
        getAll: () =>
            axios.get<Blocks>(`${appConfig.apiUrl}/booking`, {
                headers: JwtStore.getAuthHeaders()
            }),
        getAllForUser: () =>
            axios.get<BookingVM[]>(`${appConfig.apiUrl}/booking/user`, {
                headers: JwtStore.getAuthHeaders()
            }),
        cancelBooking: (data: CancelBookingDto) =>
            axios.post<SimpleResponse>(`${appConfig.apiUrl}/booking/cancel`, data, {
                headers: JwtStore.getAuthHeaders()
            }),
        book: (data: BookingDto) =>
            axios.post<void>(`${appConfig.apiUrl}/booking`, data, {
                headers: JwtStore.getAuthHeaders()
            })
    },
    payments: {
        test: () =>
            axios.post(`${appConfig.apiUrl}/payments/test`, null, {
                headers: JwtStore.getAuthHeaders()
            }),
        getPackDetails: (type: CreditType, pack: CreditPack) =>
            axios.get<string>(`${appConfig.apiUrl}/payments/details/${type}/${pack}`, {
                headers: JwtStore.getAuthHeaders()
            })
    },
    hourPacks: {
        getAll: () =>
            axios.get<HourPackVM[]>(`${appConfig.apiUrl}/hour-pack`, {
                headers: JwtStore.getAuthHeaders()
            })
    },
    bands: {
        getAll: () =>
            axios.get<BandVM[]>(`${appConfig.apiUrl}/band`, {
                headers: JwtStore.getAuthHeaders()
            }),
        create: (data: CreateBandDto) =>
            axios.post<CreateBandResponse>(`${appConfig.apiUrl}/band`, data, {
                headers: JwtStore.getAuthHeaders()
            }),
        edit: (data: EditBandDto) =>
            axios.put<SimpleResponse>(`${appConfig.apiUrl}/band`, data, {
                headers: JwtStore.getAuthHeaders()
            }),
        delete: (data: DeleteBandDto) =>
            axios.post<SimpleResponse>(`${appConfig.apiUrl}/band/delete`, data, {
                headers: JwtStore.getAuthHeaders()
            }),
        
    },
    instruments: {
        getAll: () =>
            axios.get<InstrumentVM[]>(`${appConfig.apiUrl}/instrument`, {
                headers: JwtStore.getAuthHeaders()
            })
    }
}
