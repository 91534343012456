import { DownOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu } from 'antd'
import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { appConfig } from '../../app-config'
import { useAuth } from '../../hooks/use-auth'
import { LangSwitcher } from '../lang-switcher/lang-switcher'

interface HoursPreviewProps {
    label: string
    value: number
}

const HoursPreview: React.FC<HoursPreviewProps> = ({ label, value }) => (
    <div className="flex flex-col items-center justify-center whitespace-nowrap">
        <span
            className={cn('font-semibold leading-none text-center', {
                'text-blue-500': value > 0
            })}
        >
            {value}
            <span className="text-xs">h</span>
        </span>
        <span className="text-xs leading-none text-center caps-small">{label}</span>
    </div>
)

export const Header: React.FC = () => {
    const { user, logout } = useAuth()
    const { t } = useTranslation()

    const menu = (
        <Menu>
            <Menu.Item onClick={logout} key="3">
                {t('logout', { defaultValue: 'Logout' })}
            </Menu.Item>
        </Menu>
    )

    return (
        <div className="w-full h-16 bg-white shadow">
            <div className="h-full max-w-6xl px-4 mx-auto">
                <div className="flex items-center h-full">
                    <div className="grid w-full grid-cols-12">
                        <div className="flex items-center col-span-2 md:col-span-4" style={{ marginBottom: -10 }}>
                            <LangSwitcher />
                        </div>
                        <div id={appConfig.howItWorksSelectors.creditHours} className="flex flex-col items-center justify-center col-span-8 md:col-span-4">
                            <p className="text-xs font-semibold caps-small">{t('hour credits')}</p>
                            <div className="grid w-full grid-cols-4 gap-2 mt-1">
                                <HoursPreview label={t('solo', { defaultValue: 'solo' })} value={user.soloHours} />
                                <HoursPreview label={t('duo', { defaultValue: 'duo' })} value={user.duoHours} />
                                <HoursPreview label={t('band', { defaultValue: 'band' })} value={user.bandHours} />
                                <HoursPreview
                                    label={t('lesson', { defaultValue: 'lesson' })}
                                    value={user.lessonHours}
                                />
                            </div>
                        </div>
                        <div className="flex items-center ml-auto col-span-2 md:col-span-4">
                            <Dropdown overlay={menu} trigger={['click']}>
                                <button className="outline-none">
                                    <Avatar size="small" className="mr-2">
                                        {user.firstName[0]}
                                    </Avatar>
                                    <span className="hidden md:inline-block">
                                        {user.firstName}&nbsp;{user.lastName}&nbsp;
                                    </span>
                                    <DownOutlined />
                                </button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
