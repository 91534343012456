import { setup as setupBreakpoints } from '@w11r/use-breakpoint'
import { Button } from 'antd'
import 'antd/dist/antd.css'
import 'moment/locale/en-gb'
import 'moment/locale/fr'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { appConfig } from './app-config'
import { Layout } from './components/layout/layout'
import { AuthDataProvider, useAuth } from './hooks/use-auth'
import './i18n'
import { AddCredits } from './pages/add-credits/add-credits'
import { Bands } from './pages/bands/bands'
import { BookHistory } from './pages/book-history/book-history'
import { BookWizard } from './pages/book-wizard/book-wizard'
import { Home } from './pages/home/home'
import { Login } from './pages/login/login'
import { Register } from './pages/register/register'
import './styles/index.css'
import './styles/tailwind.css'
import './wydr'

setupBreakpoints({
    breakpoints: {
        // same as tailwind
        mobile: [0, 767],
        desktop: [768, 1023],
        lg: [1024, 1279],
        xl: [1280, 1535],
        xl2: [1536, 10000]
    }
})

const PrivateRoute = (props: RouteProps) => {
    const { component, ...otherProps } = props
    const { user } = useAuth()

    if (!user.email) {
        return (
            <Redirect
                to={{
                    pathname: appConfig.pathnames.login
                }}
            />
        )
    }

    return <Route component={component} {...otherProps} />
}

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
    console.log({ error })
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <p>The technical team has been notified of this error and will fix it as soon as possible.</p>
            <Button onClick={resetErrorBoundary}>Try again</Button>
        </div>
    )
}

ReactDOM.render(
    <BrowserRouter>
        <Suspense fallback={<div className="fixed inset-0 bg-black" />}>
            <AuthDataProvider>
                <main className="absolute inset-0 bg-black">
                    <Switch>
                        <Route exact path={appConfig.pathnames.login} component={Login} />
                        <Route exact path={appConfig.pathnames.register} component={Register} />
                        <Layout>
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                <PrivateRoute
                                    path=""
                                    component={() => (
                                        <>
                                            <Route exact path={appConfig.pathnames.home} component={Home} />
                                            <Route
                                                exact
                                                path={appConfig.pathnames.allBookings}
                                                component={BookHistory}
                                            />
                                            <Route
                                                path={`${appConfig.pathnames.book}/:bookingType?/:room?`}
                                                component={BookWizard}
                                            />
                                            <Route path={appConfig.pathnames.bookSuccess} component={BookWizard} />
                                            <Route exact path={appConfig.pathnames.bands} component={Bands} />
                                            <Route
                                                exact
                                                path={appConfig.pathnames.buyCreditHours}
                                                component={AddCredits}
                                            />
                                            <Route
                                                exact
                                                path={`${appConfig.pathnames.buyCreditHours}/:creditType`}
                                                component={AddCredits}
                                            />
                                            <Route
                                                exact
                                                path={`${appConfig.pathnames.buyCreditHours}/:creditType/:successOrFailure`}
                                                component={AddCredits}
                                            />
                                        </>
                                    )}
                                />
                            </ErrorBoundary>
                        </Layout>
                    </Switch>
                </main>
            </AuthDataProvider>
        </Suspense>
    </BrowserRouter>,
    document.getElementById('root')
)
