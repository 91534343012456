import { Blocks } from '@kazoo/shared'
import { Button, InputNumber } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookingSummary } from '../../components/booking-summary/booking-summary'
import GuitarAmp from '../../images/guitar_amp.png'
import MicStand from '../../images/mic_stand.png'

interface Step3CommentsProps {
    handleBook: (comment?: string) => void
    userBlocks: Blocks
}

export const Step3Comments: React.FC<Step3CommentsProps> = ({ handleBook, userBlocks }) => {
    const [numOfGuitarAmps, setNumOfGuitarAmps] = useState(0)
    const [numOfMics, setNumOfMics] = useState(0)
    const [numOfBassAmps, setNumOfBassAmps] = useState(0)
    const [numOfKeyboards, setNumOfKeyboards] = useState(0)
    const [numOfDrumKit, setNumOfDrumKit] = useState(0)
    const [comment, setComment] = useState('')
    const { t } = useTranslation()

    return (
        <div>
            <div className="md:grid md:grid-cols-12 md:gap-4">
                <div className="md:col-span-6">
                    <p className="text-xl font-bold">
                        {t('num_of_guitar_amps', { defaultValue: 'Number of guitar amps' })}
                    </p>
                    <div className="flex items-center space-x-2 mt-2">
                        <img className="h-28" src={GuitarAmp} alt="Guitar amplifier" />
                        <InputNumber
                            min={0}
                            size="large"
                            max={4}
                            value={numOfGuitarAmps}
                            onChange={(value) => setNumOfGuitarAmps(value)}
                        />
                    </div>
                </div>
                <div className="md:col-span-6 mt-8 md:mt-0">
                    <p className="text-xl font-bold">{t('num_of_mics', { defaultValue: 'Number of mics' })}</p>
                    <div className="flex items-center space-x-2 mt-2">
                        <img className="h-28" src={MicStand} alt="Microphones" />
                        <InputNumber
                            size="large"
                            min={0}
                            max={6}
                            value={numOfMics}
                            onChange={(value) => setNumOfMics(value)}
                        />
                    </div>
                </div>
                <div className="md:col-span-6 mt-8 md:mt-0">
                    <p className="text-xl font-bold">{t('num_of_bass_amps', { defaultValue: 'Number of bass amps' })}</p>
                    <div className="flex items-center space-x-2 mt-2">
                        <InputNumber
                            size="large"
                            min={0}
                            max={2}
                            value={numOfBassAmps}
                            onChange={(value) => setNumOfBassAmps(value)}
                        />
                    </div>
                </div>
                <div className="md:col-span-6 mt-8 md:mt-0">
                    <p className="text-xl font-bold">{t('num_of_keyboards', { defaultValue: 'Number of keyboards' })}</p>
                    <div className="flex items-center space-x-2 mt-2">
                        <InputNumber
                            size="large"
                            min={0}
                            max={2}
                            value={numOfKeyboards}
                            onChange={(value) => setNumOfKeyboards(value)}
                        />
                    </div>
                </div>
                <div className="md:col-span-6 mt-8 md:mt-0">
                    <p className="text-xl font-bold">{t('num_of_drums_kit', { defaultValue: 'Number of drums kit' })}</p>
                    <div className="flex items-center space-x-2 mt-2">
                        <InputNumber
                            size="large"
                            min={0}
                            max={1}
                            value={numOfDrumKit}
                            onChange={(value) => setNumOfDrumKit(value)}
                        />
                    </div>
                </div>
            </div>

            <div>
                <p className="text-xl mb-2 mt-8 font-bold">
                    {t('comments_requests', { defaultValue: 'Requests / comments' })}
                </p>
                <TextArea className="md:w-2/4" onChange={(e) => setComment(e.target.value)} value={comment} />
            </div>

            <div>
                <p className="text-xl mb-2 mt-8 font-bold">
                    {t('booking_summary', { defaultValue: 'Booking summary' })}
                </p>
                <BookingSummary blocks={userBlocks} />
            </div>

            <Button
                type="primary"
                className="mt-8"
                size="large"
                onClick={() => {
                    handleBook(`
                        ${numOfGuitarAmps !== 0 && `${numOfGuitarAmps} guitar amps.`} 
                        ${numOfMics !== 0 && ` ${numOfMics} mics.`} 
                        ${numOfBassAmps !== 0 && ` ${numOfBassAmps} bass amps.`} 
                        ${numOfKeyboards !== 0 && ` ${numOfKeyboards} keyboards.`} 
                        ${numOfDrumKit !== 0 && ` ${numOfDrumKit} drum kit.`} 
                        ${comment ? ` ${comment}` : ''}`
                    )
                }}
            >
                {t('book', { defaultValue: 'Book' })}
            </Button>
        </div>
    )
}
