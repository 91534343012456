import { BookingVM, momentTimezone } from "@kazoo/shared";
import useDocumentTitle from '@tanem/use-document-title'
import { Card, notification, Spin } from 'antd'
import cn from 'classnames'
import moment from 'moment'
import React, { useCallback } from 'react'
import { useAsync, useAsyncCallback } from 'react-async-hook'
import { useTranslation } from 'react-i18next'
import { BookingInfo } from '../../components/booking-info/booking-info'
import { useAuth } from '../../hooks/use-auth'
import { api } from '../../services/api'

type GroupedBookings = Record<string, Record<string, BookingVM[]>>

type MonthNumber = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11'

const monthNames: Record<MonthNumber, Record<string, string>> = {
    '0': {
        en: 'January',
        fr: 'Janvier'
    },
    '1': {
        en: 'February',
        fr: 'Février'
    },
    '2': {
        en: 'March',
        fr: 'Mars'
    },
    '3': {
        en: 'April',
        fr: 'Avril'
    },
    '4': {
        en: 'May',
        fr: 'Mai'
    },
    '5': {
        en: 'June',
        fr: 'Juin'
    },
    '6': {
        en: 'July',
        fr: 'Juillet'
    },
    '7': {
        en: 'August',
        fr: 'Août'
    },
    '8': {
        en: 'September',
        fr: 'Septembre'
    },
    '9': {
        en: 'October',
        fr: 'Octobre'
    },
    '10': {
        en: 'November',
        fr: 'Novembre'
    },
    '11': {
        en: 'December',
        fr: 'Décembre'
    }
}

const getTranslatedMonth = (month: MonthNumber, language: 'en' | 'fr'): string => {
    return monthNames[month][language]
}

const groupBookingsByYearAndMonth = (bookings: BookingVM[]): GroupedBookings => {
    return bookings
        .sort((a, b) => {
            return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        })
        .reduce<GroupedBookings>((acc, booking) => {
            const bookingStart = new Date(booking.startDate)
            const yearOfBooking = bookingStart.getFullYear()
            const monthOfBooking = bookingStart.getMonth()

            if (acc[yearOfBooking]?.[monthOfBooking]) {
                acc[yearOfBooking][monthOfBooking].push(booking)
            } else {
                acc[yearOfBooking] = acc[yearOfBooking] ?? {}
                acc[yearOfBooking][monthOfBooking] = [booking]
            }

            return acc
        }, {})
}

export const BookHistory: React.FC = () => {
    useDocumentTitle('Kazoo - Bookings')
    const { t, i18n } = useTranslation()
    const { refreshUser } = useAuth()
    const { result: bookingsResponse, loading, execute: refreshBookings } = useAsync(api.bookings.getAllForUser, [])
    const { execute: cancelBooking } = useAsyncCallback(api.bookings.cancelBooking)
    const bookings = bookingsResponse?.data
    const now = moment.tz(momentTimezone)

    const onBookingCancel = useCallback(
        (bookingVM: BookingVM) => {
            cancelBooking({ bookingVM })
                .then((response) => {
                    if (response.data.ok) {
                        void notification.success({
                            message: t(
                                `Booking successfully cancelled. The hour credits were added back to your account.`
                            )
                        })
                        refreshUser()
                        refreshBookings()
                    } else {
                        throw new Error(response.data.message)
                    }
                })
                .catch((error) => {
                    void notification.error({
                        message:
                            error?.message ||
                            t(
                                'There was an error cancelling this booking. The technical team is investigating this issue.'
                            )
                    })
                })
        },
        [cancelBooking, refreshBookings, refreshUser, t]
    )

    if (loading) {
        return <Spin />
    } else if (!bookings?.length) {
        return <p className="text-2xl">{t('no_bookings', { defaultValue: 'You have no bookings yet' })}</p>
    }

    const groupedBookings = groupBookingsByYearAndMonth(bookings)

    return (
        <div>
            {Object.keys(groupedBookings).map((year) => {
                const months = Object.keys(groupedBookings[year])

                return (
                    <div key={year}>
                        <p className="text-2xl font-bold">{year}</p>
                        {months.reverse().map((month, idx) => (
                            <div
                                key={month}
                                className={cn({
                                    'mt-6': idx !== 0
                                })}
                            >
                                <p className="mb-4 text-xl">
                                    {getTranslatedMonth(month.toString() as MonthNumber, i18n.language as 'en' | 'fr')}
                                    &nbsp;
                                    <span>({groupedBookings[year][month].length})</span>
                                </p>
                                <Card>
                                    {groupedBookings[year][month].map((booking) => {
                                        const bookingStart = moment(booking.startDate)
                                        const cancellable = moment.duration(bookingStart.diff(now)).asHours() > 1
                                        return (
                                            <BookingInfo
                                                onCancel={cancellable ? onBookingCancel : undefined}
                                                key={booking.id}
                                                booking={booking}
                                            />
                                        )
                                    })}
                                </Card>
                            </div>
                        ))}
                    </div>
                )
            })}
        </div>
    )
}
