import { useCallback, useEffect, useState } from 'react'
import io from 'socket.io-client'
import { JwtStore } from '../services/jwt-store'

export const useSocket = (url: string) => {
    const [socket, setSocket] = useState<SocketIOClient.Socket>()
    const [connected, setIsConnected] = useState(false)
    const [error, setError] = useState<any>()

    useEffect(() => {
        setSocket(
            io(url, {
                query: {
                    jwt: JwtStore.getJwt()
                }
            })
        )
    }, [url])

    useEffect(() => {
        if (!socket) {
            return
        }

        socket.on('connect', () => {
            setError(undefined)
            setIsConnected(socket.connected)
            console.info('WS connected')
        })
        socket.on('reconnect', (attemptNumber: number) => {
            setError(undefined)
            console.info(`WS connection reconnected in ${attemptNumber} attemp(s).`)
        })
        socket.on('disconnect', () => {
            setIsConnected(socket.connected)
            console.info('WS disconnected')
        })
        socket.on('error', (error: any) => {
            setError(error)
            console.warn(`WS connection error`, error)
        })
        socket.on('exception', (error: any) => {
            setError(error?.message || 'An error has occurred')
            console.warn(`WS connection exception`, error)
        })
    }, [socket])

    const clearError = useCallback(() => {
        setError('')
    }, [])

    return { socket, connected, error, clearError }
}
