import useBreakpoint from '@w11r/use-breakpoint'
import { Layout as AntdLayout, notification } from 'antd'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'
import { appConfig } from '../../app-config'
import { useAuth } from '../../hooks/use-auth'
import { Header } from '../header/header'
import { DesktopMenu } from './desktop-menu'
import './layout.scss'
import { MobileMenu } from './mobile-menu'

export const Layout: React.FC = ({ children }) => {
    const { isMobile } = useBreakpoint()
    const history = useHistory()
    const { user } = useAuth()

    useEffect(() => {
        notification.config({
            top: 80,
            duration: 10
        })

        if (appConfig.isProd) {
            ReactGA.initialize('UA-176987834-2', {
                gaOptions: {
                    userId: user.id,
                    name: `${user.firstName} ${user.lastName}`
                }
            })
            history.listen((location) => {
                ReactGA.pageview(location.pathname)
            })
        }
    })

    return (
        <AntdLayout style={{ height: '100vh', flexDirection: 'row' }}>
            {!isMobile && <DesktopMenu />}
            <AntdLayout className="items-center w-100vh">
                <Header />
                <AntdLayout.Content className="md:w-full max-w-6xl px-4 py-4 pb-8 md:py-8 overflow-x-hidden overflow-y-auto mb-16 md:mb-0">
                    {children}
                </AntdLayout.Content>
                {isMobile && <MobileMenu />}
            </AntdLayout>
        </AntdLayout>
    )
}
