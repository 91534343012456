import { UserOutlined } from '@ant-design/icons'
import { CreditType, PaymentsWebsocketDataVM, paymentWebsocketRoom } from '@kazoo/shared'
import useDocumentTitle from '@tanem/use-document-title'
import { Button, Result, Spin, Steps, StepsProps } from 'antd'
import { ResultStatusType } from 'antd/lib/result'
import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'
import { appConfig } from '../../app-config'
import { BigassButton } from '../../components/bigass-button/bigass-button'
import { useAuth } from '../../hooks/use-auth'
import { useSocket } from '../../hooks/use-socket'
import DuoImage from '../../images/duo.jpg'
import LessonImage from '../../images/lesson.jpg'
import BandRehearsalImage from '../../images/rehearsal.jpg'
import SoloImage from '../../images/solo.jpg'
import { goToBookingWizard, goToBuyCredits, goToBuyCreditType } from '../../utils/routing'
import './add-credits.scss'
import { ChooseCredit } from './choose-credit'
import { loadWalleeScript, openWalleeLightbox } from './load-wallee-script'

interface MatchParams {
    creditType?: CreditType
    successOrFailure?: undefined | 'success' | 'failure'
}

export const AddCredits: React.FC<RouteComponentProps<MatchParams>> = (props) => {
    useDocumentTitle('Kazoo - Buy credits')
    const { match } = props
    const { t } = useTranslation()
    const { user, refreshUser } = useAuth()
    const [success, setSuccess] = useState(false)
    const [declined, setDeclined] = useState(false)
    const [voided, setVoided] = useState(false)
    const [failed, setFailed] = useState(false)
    const creditType = match.params.creditType
    const successOrFailure = match.params.successOrFailure
    const { socket, connected, error, clearError } = useSocket(`${appConfig.apiUrl}/payment`)
    const [processing, setProcessing] = useState(false)

    const currentStep = useMemo(() => {
        let step = 0
        if (success || declined || voided || failed || error) {
            step = 2
        } else if (creditType) {
            step = 1
        }
        return step
    }, [creditType, declined, error, failed, success, voided])

    useEffect(() => {
        if (successOrFailure === 'success') {
            setSuccess(true)
        } else if (successOrFailure === 'failure') {
            setFailed(true)
        }
    }, [successOrFailure])

    useEffect(() => {
        if (socket && connected) {
            socket.on(user.id, (response: PaymentsWebsocketDataVM) => {
                if (response.data.scriptURL) {
                    loadWalleeScript(response.data.scriptURL, () => {
                        openWalleeLightbox()
                    })
                } else if (response.data.success) {
                    refreshUser()
                    setSuccess(true)
                    setProcessing(false)
                } else if (response.data.declined) {
                    setDeclined(true)
                    setProcessing(false)
                } else if (response.data.failed) {
                    setFailed(true)
                    setProcessing(false)
                } else if (response.data.voided) {
                    setVoided(true)
                    setProcessing(false)
                } else {
                    setProcessing(false)
                    console.warn('Websocket response could not be handled', response)
                }
            })
        }
    }, [connected, refreshUser, socket, user.id])

    const handlePurchase = useCallback(
        (packId: string) => {
            setProcessing(true)
            socket?.emit(paymentWebsocketRoom, {
                body: {
                    packId
                }
            })
        },
        [socket]
    )

    const onProcessingCancel = useCallback(() => {
        setProcessing(false)
    }, [])

    const getFinalPage = useCallback(() => {
        let status: ResultStatusType = 'info'
        let title = ''
        let subtitle = null
        let primaryButton = null
        let secondaryButton = null

        if (success) {
            status = 'success'
            title = t('purchase_success', { defaultValue: 'Your purchase was successful!' })
            primaryButton = (
                <Link key={1} to={goToBookingWizard()}>
                    <Button type="primary">{t('purchase_success_page_goto_book', { defaultValue: 'Book now' })}</Button>
                </Link>
            )
            secondaryButton = (
                <Link key={2} to={goToBuyCredits()}>
                    <Button>{t('purchase_success_page_goto_buy_more', { defaultValue: 'Buy more credits' })}</Button>
                </Link>
            )
        } else if ((error || failed) && creditType) {
            status = 'error'
            title = t('payment_failure_page_title', { defaultValue: 'There was an error during the payment' })
            subtitle = t('payment_failure_page_subtitle', { defaultValue: 'You have not been charged.' })
            primaryButton = (
                <Link key="error_try_again" to={goToBuyCreditType(creditType)}>
                    <Button
                        onClick={() => {
                            if (creditType) {
                            }
                            clearError()
                            setFailed(false)
                        }}
                    >
                        {t('try_again', { defaultValue: 'Try again' })}
                    </Button>
                </Link>
            )
        } else if ((declined || voided) && creditType) {
            status = 'warning'
            title = t('payment_declined_page_title', { defaultValue: 'The payment was declined' })
            subtitle = t('payment_declined_page_subtitle', { defaultValue: 'You have not been charged.' })
            primaryButton = (
                <Link key="declined_try_again" to={goToBuyCreditType(creditType)}>
                    <Button
                        onClick={() => {
                            if (creditType) {
                                goToBuyCreditType(creditType)
                            }
                            setDeclined(false)
                        }}
                    >
                        {t('try_again')}
                    </Button>
                </Link>
            )
        }

        return <Result status={status} title={title} subTitle={subtitle} extra={[primaryButton, secondaryButton]} />
    }, [clearError, creditType, declined, error, failed, success, t, voided])

    return (
        <div>
            <div className="hidden md:block mb-8">
                <StepsInfo
                    currentStep={currentStep}
                    failed={failed}
                    declined={declined}
                    voided={voided}
                    processing={processing}
                    creditType={creditType}
                />
            </div>
            <div className="md:hidden mb-4">
                <StepsInfo
                    className="mb-0"
                    size="small"
                    direction="vertical"
                    currentStep={currentStep}
                    failed={failed}
                    declined={declined}
                    voided={voided}
                    processing={processing}
                    creditType={creditType}
                />
            </div>

            {currentStep === 0 && (
                <div className="grid max-w-4xl grid-cols-1 mx-auto gap-x-8 gap-y-8 md:grid-cols-2">
                    <Link to={goToBuyCreditType(CreditType.SOLO)}>
                        <BigassButton image={SoloImage} label={t('buy_option_solo', { defaultValue: 'Solo' })}>
                            <UserOutlined />
                            &nbsp;&nbsp;(1 {t('person', { defaultValue: 'person' })})
                        </BigassButton>
                    </Link>
                    <Link to={goToBuyCreditType(CreditType.DUO)}>
                        <BigassButton image={DuoImage} label={t('buy_option_duo', { defaultValue: 'Duo' })}>
                            <UserOutlined /> <UserOutlined />
                            &nbsp;&nbsp;(2 {t('persons', { defaultValue: 'persons' })})
                        </BigassButton>
                    </Link>
                    <Link to={goToBuyCreditType(CreditType.BAND)}>
                        <BigassButton image={BandRehearsalImage} label={t('buy_option_band', { defaultValue: 'Band' })}>
                            <UserOutlined /> <UserOutlined /> <UserOutlined />
                            &nbsp;&nbsp;(3+ {t('persons')})
                        </BigassButton>
                    </Link>
                    {/*<Link to={goToBuyCreditType(CreditType.LESSON)}>*/}
                        <BigassButton disabled={true} image={LessonImage} label={t('buy_option_lesson', { defaultValue: 'Lesson' })}>
                            <UserOutlined /> <UserOutlined />
                            &nbsp;&nbsp;(2+ {t('persons')})
                        </BigassButton>
                    {/*</Link>*/}
                </div>
            )}

            {currentStep === 1 && creditType && (
                <div className="grid max-w-4xl grid-flow-col mx-auto gap-x-8">
                    {processing ? (
                        <ProcessingInfo onCancel={onProcessingCancel} />
                    ) : (
                        <ChooseCredit type={creditType} handlePurchase={handlePurchase} />
                    )}
                </div>
            )}

            {currentStep === 2 && <div>{getFinalPage()}</div>}
        </div>
    )
}

interface ProcessingInfoProps {
    onCancel: () => void
}

const ProcessingInfo: React.FC<ProcessingInfoProps> = ({ onCancel }) => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col items-center justify-center mt-0 md:mt-20">
            <div>
                <span className="mr-2">
                    <Spin />
                </span>
                {t('processing_payment', { defaultValue: 'Processing your payment' })}...
            </div>

            <div className="mt-2">
                {t('processing_dont_close_window', {
                    defaultValue: 'Please do not close or refresh this tab. It will update automatically.'
                })}
            </div>
        </div>
    )
}

interface StepsInfoProps extends StepsProps {
    currentStep: number
    failed: boolean
    processing: boolean
    declined: boolean
    voided: boolean
    creditType?: CreditType
}

export const StepsInfo = (props: StepsInfoProps) => {
    const { currentStep, failed, processing, declined, voided, creditType, ...stepsProps } = props
    const { t } = useTranslation()

    return (
        <Steps
            current={currentStep}
            status={failed ? 'error' : undefined}
            className={cn({
                'mb-10': currentStep === 0,
                'mb-4': currentStep !== 0
            })}
            {...stepsProps}
        >
            <Steps.Step
                key={0}
                title={
                    <Link to={goToBuyCredits()}>
                        <span className="text-base whitespace-normal">
                            {t('buy_page_hour_credit_type', { defaultValue: 'Hour Credit Type' })}
                        </span>
                    </Link>
                }
            />
            <Steps.Step
                key={1}
                title={
                    currentStep >= 1 ? (
                        <Link to={goToBuyCreditType(creditType ?? CreditType.SOLO)}>
                            <span className="text-base whitespace-normal">
                                {t('buy_page_choose_&_pay', { defaultValue: 'Choose & Pay' })}
                            </span>
                        </Link>
                    ) : (
                        <span className="text-base whitespace-normal">
                            {t('buy_page_choose_&_pay', { defaultValue: 'Choose & Pay' })}
                        </span>
                    )
                }
            />
            <Steps.Step
                key={2}
                title={
                    <span className="text-base whitespace-normal">
                        {failed || declined || voided
                            ? t('failure', { defaultValue: 'Failure' })
                            : t('success', { defaultValue: 'Success' })}
                    </span>
                }
            />
        </Steps>
    )
}
